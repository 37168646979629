export default {
    en: {
        comingSoon: "Coming soon...",
        goToTop: "To top",
        appVersionTitle: "Version",
        btnOk: "Ok",
        btnAccept: "Accept",
        btnAdd: "Add",
        btnAdded: "Added",
        btnBack: "Back",
        btnSave: "Save",
        btnSend: "Send",
        btnShow: "Show",
        btnView: "View",
        btnEdit: "Edit",
        btnClose: "Close",
		btnRoles: "Roles",
        btnCreate: "Create",
        btnUpload: "Upload",
        btnUpdate: "Update",
        btnDelete: "Delete",
        btnCancel: "Cancel",
        btnExport: "Export",
		btnFilter: "Filter",
		btnSearch: "Search",
		btnDownload: "Download",
        btnSaveSend: "Save & Send",
        btnSettings: "Settings",
        btnExporting: "Exporting",
        btnLeavePage: "Leave the page",
        btnXlsExport: "Excel Export",
		btnPdfExport: "PDF Export",
        btnShowResults: "Results",
		btnFilterCancel: "Remove filters",
		btnShowLogResults: "Show logs",
		btnShowDiagResults: "Show diagnostics",
        btnNewPwdRequired: "Request a new password",
        btnBackToTheLogin: "Back to the Login Page",
		aServiceIsNotWorking: "We're sorry, but this service is not working at the moment.",
        btnBackToTheMainPage: "Back to the Main Page",
        validateAttentionText: "Attention! I have encountered the following problem(s):",
        validateErrorNameText: "Use a space in the name and it must be at least 5 characters long.",
        validateNullNameText: "The name field is not be to empty.",
        validateNullRoleText: "Please select a user group",
        validateErrorEmailText: "Please fill the valid email address",
        validateNullEmailText: "Please fill the email address",
        validateErrorPasswordText: "The password must be between 8 and 40 characters long and contain upper and lower case letters, numbers and special characters.",
        validateNullPasswordText: "Please fill the password",
        validateNotEqualPasswordsText: "The passwords do not match",
        validateLoginWrongAccount: "Incorrect E-mail or password",
        validateForgotPasswordWrongText: "No user associated with this email address.",
        defaults: {
			all: "All",
			exit: "Exit",
			enter: "Enter",
			content: "Content",
			everyone: "Everyone",
			initMaps: "Initializing the map, please wait...",
            initializing: "Initialization, please wait...",
            addAllFields: "Please complete all fields",
            confirm: "Confirmation",
            loading: "Loading, please wait...",
            uploading: "Uploading & importing, please wait...",
			text403: "You are not authorised to view the requested page.",
			title403: "Privilege error",
			text404: "The page you are looking for was not found.",
			title404: "Oops! You're lost.",
            required: "Required",
			registerDate: "Date of register",
			attention: "Attention!",
            operation: "Operation",
            updatedField: "Updated date",
            footerText: "Centre for Social Sciences",
            pleaseSelect: "Please select...",
			serverError: "Server-side error",
			latitude: "Latitude",
			longitude: "Longitude",
			fieldMeterText: "meter",
			fieldMinuteText: "minute",
			fieldSecondText: "second(s)",
			uploadPHImageText: "Choose an image file (svg, jpg, png)",
			gotoTheMainPage: "Back to the main page",
			failUpdate: "Please check that you have provided all the required information!",
            wrongFileFormat: "Invalid file format!",
			wrongScreenSize: "Recommended resolution to use the system: HD (1920x1080px)",
            successSaveProcess: "Saved successfully.",
			successCreateProcess: "Created successfully.",
			successUpdateProcess: "Updated successfully.",
			successDeleteProcess: "Remove successfully.",
			successDraggedProcess: "Moved successfully.",
			errorMultipleSelectNotNull: "The drop-down box must contain at least one item!",
			cannotLogoutFromNewSurvey: "Attention! While you are editing the basic data of a new survey, you cannot leave the system!",
			cannotLogoutFromNewProject: "Attention! As long as you do not save when editing the basic data of a new project, you cannot leave the system!",
			filters: {
				filterText: "Please enter a filter condition...",
				loadedNums: "The number of loaded users ",
				loadedResps: "The number of loaded respondents: ",
				elemPerPage: "items / page",
			}
        },
        days: {
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thrusday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday"
        },
        statuses: {
            active: "Active",
            deactive: "Deactive"
        },
        regVerifier: {
            name: "Register verifier",
            process: "Checking, please wait...",
            successMessage: "Your registration has been successfully confirmed!"
        },
        usersTable: {
            titleName: "Name",
            titleEmail: "Email",
            titleAddTo: "Add to project",
            titleRegTo: "Send registration email"
        },
        login: {
            title: "Login",
            text: "Sign in to your account.",
            lblEmail: "Enter your email address:",
            lblPassword: "Enter your password:",
            reLoginText: "The login time has expired, so please log in again",
            phEmail: "Email Address",
            phPassword: "Password",
            btnCheck: "Checking...",
            btnLogin: "Login to system",
            btnForgotPass: "Forgot password?",
            validating: {
                wrongAuth: "Your login details are not correct, so please try again",
                errorEmail: "Please fill the valid email address",
                errorPassword: "The password must be between 8 and 40 characters long and contain upper and lower case letters, numbers and special characters.",
                errorSimplePassword: "Please add the valid password",
				userRespondent: "Attention! Users who currently have responder privileges are not allowed to log in!",
            }
        },
        forgotPassword: {
            title: "Forgot password",
            text: "Enter your real email address to which we can send the new password",
            process: "Email sending, please wait...",
            successMessage: "We have successfully updated your password, which was sent to you by email."
        },
        breadcrumbs: {
            home: "Home",
        },
        dashboard: {
			title: "Dashboard",
			desc: "Below is a summary of the current projects and questionnaires that belong to the user.",
			cardProjects: "Active projects",
			cardSurveys: "Surveys",
			cardPanelMembers: "Panel members",
			lastModified: "Last modified",
			project: "Project",
			createdDate: "Created date",
			hasNoProject: "It has no project yet",
			blockProjectTitle: "Actual projects",
			blockProjectDesc: "The block below shows the projects currently running.",
			blockSurveyTitle: "Actual surveys",
			blockSurveyDesc: "The block below shows the surveys currently running.",
        },
        headerMenu: {
            dashboard: "Dashboard",
            settings: "Settings",
            logout: "Logout"
        },
        sidebarMenu: {
            mainmenu: "Main Menu"
        },
        settings: {
            title: "Settings"
        },
        notifications: {
            btnAdd: "New notification",
            titles: {
                list: "Email notifications",
                edit: "Edit email notification",
            },
            descs: {
                list: "Az alábbiakban láthatóak az eddig kiküldött regisztrációs email értesítők listája",
                edit: "Az alábbi form segítségével szerkesztheted az email értesítésedet, amelynek segítségével értesülhetnek a felhasználók az új regisztrációs igényről.",
            },
            table: {
                name: "Name of the notification",
                type: "Type of the notification",
                updated: "Updated",
                hasUsers: "Existing respondents",
                newUsers: "New respondents",
                surveyName: "Name of the registration form",
                sendElemNum: "Number of the sended questions",
                answeredElemNum: "Number of answered questions",
            },
            form: {
                lbName: "Notification name",
                lbLang: "Choose the language",
                lbType: "Choose the type of the notification",
                lbSurvey: "Choose the registration form",
                lbAddresses: "Add the email addresses",
                lbEmailTemp: "Choose the email template",
                phName: "Name the notification",
                phType: "Select respondent to be notified",
                phSurvey: "Select the registration form",
                phAddresses: "List email addresses separated by ENTER.",
            },
            types: {
                hasuser: "Existing respondents",
                newuser: "New respondents",
            },
            validating: {
                name: "Please add a name for the notification! Number of characters between 5 and 100.",
                type: "Please select between new or existing respondents to be notified!",
                regsurvey: "Please select a registration form!",
            },
            messages: {
                saveSuccess: "The notification has been saved successfully.",
            }
        },
        clients: {
            titles: {
                list: "Clients",
                edit: "Editing client data"
            },
            descs: {
                list: "Below is the Clients added to the system so far.",
                edit: "You can edit your client details using the form below."
            },
            form: {
                lbName: "Client's name",
                lbCode: "Client's unique code",
                lbEmail: "Client's email address",
				lbLang: "Client's default language",
                lbPhone: "Client's phone number",
                lbAddress: "Client's address",
                lbConName: "Contact's name",
                lbConEmail: "Contact's email address",
                lbConPhone: "Contact's phone number",
                lbConPos: "Contact's position",
                lbRegister: "Client registration form",
                lbActive: "Client activation status",
                phName: "Enter the name of client",
                phCode: "Enter an unique code of client",
                phEmail: "Enter the email address of client",
                phPhone: "Enter the phone number of client",
				phLang: "Select a language",
				phMoreLang: "Choose at least one language from the options",
                phAddress: "Enter the address of client",
                phConName: "Enter the name of contact",
                phConEmail: "Enter the email address of contact",
                phConPhone: "Enter the phone number of contact",
                phConPos: "Enter the position of contact",
                phRegister: "Select a registration form (optional)",
                btnGenerate: "Generate to client code",
                btnGenerating: "Generating in progress..."
            },
            modals: {
                deleteDesc: "Are you sure you want to delete the selected client? If you do, the users associated with that client will can not be able to log in, start or participate in a project.",
                deleteTitle: "Delete the selected client",
                warningDesc: "You have generated a new code for the client. If you leave the page now, the changes will not be saved. If you want to save the changes, close this window and press the Update button.",
                warningTitle: "Are you sure leave the page?",
                generateTitle: "Generate new client's code",
                generateDesc: "Are you sure you want to generate new client's code? Changing the code may affect further processes.",
                statusTitle: "Change client status",
                statusOnDesc: "If you turn it back on, the client and its users will be able to log back in and the client's projects will be available again.",
                statusOffDesc: "If you turn it off, the users associated with that client will can not be able to log in, start or participate in a project.",
            },
            messages: {
                deleteSuccess: "The client delete has been successfully",
                updateSuccess: "The client's details have been successfully updated.",
                updateStatusSuccess: "The client's status has been successfully updated",
            },
            validating: {
                name: "Enter the client name (required)",
                email: "Enter the client email address through which you can be reached",
                code: "Enter the unique identifier of the client - click on the Generate client code button"
            },
            clientName: "Client name",
            clientEmail: "Client email address",
            clientStatus: "Client's status",
            btnAddNewClient: "Add new client"
        },
        users: {
            addUser: "Add a new user",
            editUser: "Edit a user",
            userProfile: "Edit my profile",
            userForProject: 'Set additional properties for the "{pname}" project.',
            userForProjectSubTitle: "Provide as much information as you can to maximise your chances of being included in the sampling process.",
            userList: "Managing users",
            userSubTitle: "Fill in all the elements of the form and press the save button.",
			successSave: "The new user has been saved in the system.",
			successUpdate: "Successfully updated the user.",
			successDelete: "Successfully deleted the user.",
			wrongAuthDatas: "As we have not been able to clearly define your user rights, please log in again!",
            userListSubTitle: "The table below shows the users present in the system. You can edit/delete data according to your rights.",
            editFormNameTitle: "Name",
            editFormEmailTitle: "Email",
            editFormRoleTitle: "The user belongs to this group",
            editFormClientTitle: "The user belongs to this client",
            editFormPasswordTitle: "Password",
            editFormRePasswordTitle: "Password again",
            errorNameTitle: "The name field is not be to empty",
            modalTitle: "Confirm delete user",
            modalText: "Are you sure you want to delete the selected user?",
            phRole: "Please select a role group",
            phClient: "Please select a client",
            table: {
                name: "Name",
                added: "Add researcher to project",
                registered: "Registered date",
                roles: "Role",
				rights: "Access rights",
                status: "Status",
				selectRole: "Select a role"
            },
            role: {
                res: "Researcher",
                user: "Responder",
                admin: "Administrator",
            },
            validating: {
                name: "Please enter your name! Possible number of characters: 5-100",
                email: "Please enter your real email address! Possible number of characters: 5-100",
                password: "Your password should contain lowercase and capital letter, numbers and at least one special character. Password length: 8-40 characters. Both passwords must match!"
            }
        },
		roles: {
			modals: {
				removeText: "Are you sure you want to delete the selected role?",
				removeTitle: "Confirmation of the deletion of a role",
			}
		},
        emails: {
            titles: {
                list: "Email templates",
                edit: "Edit email template",
                send: "Send email",
                show: "Show email template",
                create: "Create email template"
            },
            texts: {
                list: "The table below shows the email templates in the database that can be used for sending emails",
                edit: "Change what you want in the form below, then press the change button",
                send: "You can use the form below to send the template to a recipient for testing",
                show: "Below is the content of the email template",
                create: "Fill in all the elements of the form below, then press the save button"
            },
            table: {
                name: "Name of template",
                subject: "Subject of message",
            },
            showDatas: {
                name: "The name of template",
                subject: "The subject of template",
                content: "The content of template"
            },
            form: {
                labelEmail: "Please add an email address",
                labelName: "Please add the name of template",
                labelDelete: "Specify whether the template can be deleted or not",
                labelDefault: "Specify whether the template is the default or not",
                labelSubject: "Please add the subject of email",
                labelContent: "Please add the content of email",
                labelSendLang: "Select the language in which you want to send the template",
                labelSelectLang: "Choose the language you want to display the template in",
                phEmail: "Email address",
                phName: "The name...",
                phSubject: "The subject...",
                phContent: "The content..."
            },
            validating: {
                name: "The name of the template must be given, and must be at least 5 characters long",
                subject: "The subject of the email must be given, and must be at least 5 characters long",
                content: "The content of the email must be given, and must be at least 10 characters long",
            },
            modal: {
                title: "Confirm the deletion of a selected email template",
                text: "Are you sure you want to delete this template?"
            },
            btnCreateText: "Create a new template",
            successDeleted: "Successfully deleted Email Template.",
            successCreated: "Successfully created Email Template.",
            successUpdated: "Successfully updated the Email Template.",
            successSendEmail: "Successfully sent email."
        },
        profile: {
            menuHeader: "Account",
            menuUpdates: "Updates",
            menuMessages: "Messages",
            menuTasks: "Tasks",
            menuComments: "Comments",
            menuProfile: "Profile",
            menuSettings: "Settings",
            menuLockAccount: "Lock Account",
            menuLogout: "Logout",
        },
        peditor: {
            titles: {
                list: "Project designer",
                create: "Create new project",
                edit: "Edit a project",
                result: "Result of a project",
				logresult: "Logs of a project",
                sampling: "Sampling",
                addDefault: "Please add the project's default datas",
                addUsers: "Please add users for the project",
                addModules: "Please add modules for the project",
                addTriggers: "Set triggers for the selected module",
                addResearcher: "Please select researchers and roles from the table",
				addRespondent: "Add a new respondent for the project",
                flipTitleError: "This module isn't available",
                flipTextOk: "The module has been successfully set up. Add triggers to the module for further fine tuning.",
                flipTextFullOk: "The module has been successfully set up. You have nothing else important to do.",
                flipTextError: "This module isn't configured. You can configure it by clicking on the blue icon.",
            },
            texts: {
                list: "Below are the projects currently running. The block containing passive projects is collapsed, but you can open it at any time to see what projects you have been worked on.",
                edit: "You can edit your project using the form below",
                create: "You can put together your project using the form below",
                result: "Below you can see and download the results of the project",
				logresult: "Below are the logs for the project per user",
                actives: "Active modules",
                passives: "Passive modules",
				addAName: "Add a name...",
				addFilterText: "Enter the filter condition",
                sampling: "Use the visual editor to build your own team.",
                addUserText: "In the table you can see the added users",
                addedUserNum: "Sample: {count} respondents",
                lastModified: "Last modified: {modified}, {name}",
                addModuleText: "Use the green plus icon on the right to add modules",
                addTriggerText: "In the following panel you can set the necessary triggers for the module",
                addedResearchers: "{count} invited researcher(s)",
                addResearcherText: "You can select one or more researchers from the table below. Once you have selected one, you can set the role they can play in the project.",
                dataCollection: "Data collection",
                setViewPageText: "Change another view",
                activeProjectText: "Active projects",
                passiveProjectText: "Passive projects",
                addTriggerShowText: "In the calendar below you can see which triggers have been set",
                addNewModuleText: "Add new module",
                deleteModuleText: "Remove module",
                surveyModuleText: "Set to survey module",
                locationModuleText: "Set to location module",
				wrongAddedResearcher: "You have to also choose a privilege level for the user!",
				smsModuleText: "Set to SMS module",
				callModuleText: "Set to call module",
				audioModuleText: "Set to audio module",
				photoModuleText: "Set to photo module",
				videoModuleText: "Set to video module",
				eventsModuleText: "Set to events module",
				appUsageModuleText: "Set to app usage module",
                errorModuleText: "There is a problem with the module settings, please check!",
                triggerNumberText: "Number of triggers added to the module",
                defaultActiveText: "If active...",
                defaultTriggerText: "Then let it be that ",
                selectedActiveText: "If active the ",
                addTriggerToModuleText: "Add trigger to the module",
                selectModuleToTriggerText: "Module to be selected: ",
				expiredprojectInfoText: "This is now an expired project, so the incoming data can no longer be monitored.",
				lastUploadDate: "The exact date of the respondent's last upload: ",
				noUploadData: "Warning! No data from the respondent yet!",
				cycleStartAndEnd: "Start and end of the measurement cycle: ",
				loadedRespondentsNum: "Loaded respondent's number: {count}.",
				exportText: "The table below shows how many files have been exported so far. If you don't want a new export, you can download the existing ones. If you want to create a new export, press the button below.",
				exportProcess: "The export process may take longer depending on the number of records stored in the database!",
				noLocationData: "There is currently no location data assigned to this project in the database, so the heat map cannot be used at this time.",
            },
			views: {
				role: "Role view",
				calendar: "Calendar view",
				timeLine: "Timeline view",
			},
            form: {
                labelAcro: "Project acronym",
                labelName: "Project name",
                labelDesc: "Project's internal description. Still available: {count} chars",
				labelLang: "The project is available in these languages",
				labelAppDesc: "Description of the project in the mobile app",
                labelEndDate: "End date of the project",
                labelStartDate: "Start date of the project",
                labelRegSurveys: "Registration- or panel survey of the project",
				labelUploadProjectImage: "Upload the image for the project",
                phAcro: "Please add the project's acronym (min 2 characters)",
                phName: "Please add the project's name (min 5 characters)",
                phDesc: "Please add the project's description",
				phSurv: "Choose a survey...",
				phPlat: "Choose at least one platform",
				phImageUpload: "Add the image associated with the project",
                btnAddToProject: "Add to the project",
				phModuleImageUpload: "Add the module's icon or image file",
            },
            modal: {
                title: "Confirm the deletion of a selected project",
                moduleTitle: "Confirm the deletion of a selected module",
                surveyTitle: "Add survey to the project",
                uploadTitle: "Upload CSV with new users",
				smsTitle: "Set to SMS module",
				callTitle: "Set to call module",
				audioTitle: "Set to audio module",
				photoTitle: "Set to photo module",
				videoTitle: "Set to video module",
				eventTitle: "Setting of the Events module",
				appUsageTitle: "Setting of the App usage module",
                locationTitle: "Add location settings to the project",
                activateTitle: "Make the selected project is active",
				dailyInfoTitle: "Show the daily data",
                inactivateTitle: "Make the selected project is passive",
                loadTriggerTitle: "Loading a trigger template",
                loadGoogleMapsTitle: "Locations on the Google Maps",
                saveTriggerTitle: "Save the trigger settings",
                deleteTriggerTitle: "Delete the selected trigger template",
                text: "Are you sure you want to delete this project?",
				urlPHText: "Name of the page where you want to embed the survey",
                moduleText: "Are you sure you want to delete this module from the project?",
                surveyText: "Choose a survey from the dropdown below",
				surveyType: "Survey type:",
				surveyExpire: "Survey expiration:",
				surveyUsedNotif: "Survey can be completed in a push notification",
				surveyEmbed: "Survey can be embedded to external websites",
                uploadText: "Choose a CSV:",
                uploadPHText: "Add a CSV file...",
                uploadCancel: "No import, because there was no new data in the CSV file.",
                uploadSuccess: "The CSV file upload and the import of users was successful.",
				moduleAppText: "The module info that appears in the app:",
				moduleColorText: "The module's color:",
				moduleShortText: "Short description of the module :",
				moduleFontColorText: "Color of the letter of the module:",
                activateText: "Are you sure you want to activate to this project?",
                inactivateText: "Are you sure you want to inactivate to this project?",
                loadTriggerText: "Choose the template you want to work with from the list below.",
                saveTriggerText: "Please enter the name of trigger template:",
                deleteTriggerText: "Are you sure you want to delete the selected trigger?",
                deleteTriggerGroupText: "Are you sure you want to delete the selected trigger group?",
                deleteTriggerDefaultText: "Are you sure you want to delete the selected trigger?",
                surveyModuleText: "Enter the name of the survey module (max 15 characters)",
                confirmDeleteText: "Are you sure you want to delete the selected user from this project?",
                confirmImportText: "There is at least one user associated with the project. If you import users, the current users will be deleted.",
                locationModuleText: "Enter the name of the location module (max 15 characters)",
                surveyModuleLabel: "The name of survey module",
				smsModuleLabel: "The name of the SMS module",
				audioModuleLabel: "The name of the Audio module",
				photoModuleLabel: "The name of the Photo module",
				videoModuleLabel: "The name of the Video module",
				phoneModuleLabel: "The name of the Call module",
				eventModuleLabel: "The name of the Event module",
				appUsageModuleLabel: "The name of the App Usage module",
                locationModuleLabel: "The name of the Location module",
				frequencyLabel: "Frequency of data collection",
                freqRecordingLabel: "Frequency of data recording (minutes OR hour/hours)",
                freqRecordingText: "You can enter data e.g.: 60 OR 10:00; 11:00; 12:00 (etc.)",
				canFilterExportText: "Since you used the filter, you can export the filtered data too",
				gpsAccurary: "Please set the GPS coordinates' accurary",
				wrongFreqHourFormat1: "In the hour:minute format both values can only be numbers!",
				wrongFreqHourFormat2: "The hour can only have values between 0 and 23 and the minute between 0 and 59!",
				wrongFreqHourFormat3: "Hours and minutes must always be 2 digits!",
				wrongFreqHourFormat4: "The minimum minute is 1 and the maximum is 1440 (1 full day)",
            },
			results: {
				projectStartEndDates: "Project start date: {0}. Project finish date: {1}",
				tabCheckDatas: "Checking the data received",
				tabDatasOnDiagram: "Data on graphs",
				resultDate: "The requested date: {0}",
				exportDatas: "Export module datas",
				resultDatas: "Daily data points sent by respondent: {0}",
				resultModuleName: "Name of module: {0}",
				resultResponderName: "Responder's name: {0}",
				missingUploads: "Show respondents who have not uploaded anything this week:",
				tableUser: "Respondent's name",
				tableNrcId: "NRCID",
				tableRegister: "Register date",
				tableEmailAddress: "Email address",
				tableProgress: "Overall progress",
				tableDataProgress: "Weekly data progress",
				textNoResult: "No matches according to the specified filter criteria!",
			},
            sampling: {
                blocks: {
                    nameVars: "Variables",
                    nameStat: "Statistic",
                    nameFilters: "Filter settings",
                    nameSampling: "Additional filters",
                    nameSelectedUsers: "Selected users",
                },
                codeeditor: {
                    optFilter: "Functions",
                    optLogic: "Logical operators",
                    lblCheck: "Check",
                    helps: {
                        txtIs: "The IS function is used to determine whether or not the value of a given variable is specified. EXAMPLE: 'age' IS NULL -> this means that we are interested in matches that do not specify their age. This can also be negated, EXAMPLE: 'age' IS NOT NULL -> this will list the users where the age data was entered.",
                        txtOr: "The purpose of the OR logical variable is to return a match if at least 2 variables match the condition. EXAMPLE: 'sex' = \"man\" OR 'age' < 30 -> this means that we want to find matches that are either male or under 30. A more complex EXAMPLE: ('sex' = \"man\" AND 'age' < 25) OR ('sex' = \"man\" AND 'age' BETWEEN(25,50)) -> this means we want matches who are male AND under 25, OR male and between 25 and 50.",
                        txtAnd: "The purpose of the AND logical variable is to return a match when at least 2 variables have both values that match the conditions. EXAMPLE: 'sex' = \"woman\" AND 'age' BETWEEN(18,30) -> this means that we want users who are female and between 18-30 years old.",
                        txtAny: "The ANY function is used when there are several possible correspondences for a given variable value. EXAMPLE: eyecolor = ANY(\"brown\",\"blue\",\"green\") -> this means that we want users whose eye color is brown, green or blue. ANOTHER EXAMPLE: eyecolor = NOT ANY(\"blue\",\"green\") -> this means we want users whose eye color is not blue or green.",
                        txtMax: "The MAX function is used when we need users who have set a certain value to the maximum. EXAMPLE: MAX(age) -> this means that we need users whose age is the highest.  The function will take the largest value, so for example if there are many 70 year olds in the database, then more, and if there is only one 70 year old, then only one will be listed.",
                        txtMin: "The MIN function is used when we need users who have set a certain value to the smallest. EXAMPLE: MIN(age) -> this means that we want users whose age is the smallest. The function will take the smallest value, so for example if there are many 20 year olds in the database, then more, if there is only one 20 year old, then only one will be listed.",
                        txtNot: "The NOT logical variable can usually be used before functions (except for the IS function) to negate something. EXAMPLE: email NOT CONTAIN(\"%gmail%\") -> this means that we want to know about users who are not registered with a gmail email address. More complex EXAMPLE: age NOT BETWEEN(18,30) AND sex NOT CONTAIN(\"wo%\") -> this means we want users who are NOT 18-30 years old AND NOT women.",
                        txtNull: "The NULL parameter is used in conjunction with the IS function, so see its description.",
                        txtLimit: "The LIMIT function is used when you want to limit the number of records to be queried. EXAMPLE: LIMIT 9, 100 -> this means that we want to display only 100 elements starting from the 10th element of the retrieved records.",
                        txtBetween: "The BETWEEN function is used for more complex queries, which can be used in conjunction with other functions. Its purpose is to express more concisely the limits within which we want to display results. EXAMPLE: age = BETWEEN(MIN(age) + 5, MAX(age) - 4) AND sex = \"WOMAN\" -> this means that we want to get hits for people whose age is 5 more than the minimum and 4 less than the maximum and women. To put it even more simply, EXAMPLE: age = BETWEEN(MIN(age) + 5, MAX(age) - 4) AND sex CONTAIN(\"WO%\")",
                        txtContain: "The CONTAIN function is used when we want to find matches that match a given string pattern. EXAMPLE: email CONTAIN(\"%tk.%\") -> this means that we are looking for users whose email address contains the expression \"tk.\".",
                    },
                    statuses: {
                        noCode: "Please provide the code.",
                        noSpace: "Your code has no spaces. Please edit your code.",
                        noResult: "No results found. Please modify your code.",
                        allRight: "The provided filter looks good.",
                        loadedCode: "Previously used filter has been successfully loaded.",
                        errors: {
                            errorNotVar: "The code contains a variable that is not present on the \"variables\" panel.",
                            errorNotOperand: "There is no mathematical symbol in your code.",
                            errorNotEqualVar: "The code contains a variable that is not available in your project.",
                            errorMissingParts: "The code has variables without a value and math symbols",
                            errorLogicOperands: "The code has a logical statement without proper coding.",
                            errorUseLimitFunc1: "The parameters of the LIMIT function should be separated by a comma.",
                            errorUseLimitFunc2: "Both parameters of the LIMIT function have to be an integer.",
                            errorWrongBrackets: "Problem with the brackets in your code. Please check your code again.",
                            errorFirstLimitFunc: "The LIMIT function has to be placed at the end of your code not at the beginning.",
                            errorWrongQuoteOnVar: "The code contains a variable without proper quoting.",
                            errorNotLastLimitFunc: "The LIMIT function has to be placed at the end of your code.",
                            errorWrongQuoteOnValue: "The code contains a value without proper quoting.",
                            errorWrongISFunctionUse: "The IS function was not used correctly in your code. Please check again.",
                            errorUseBetweenFuncParam: "The parameters of the BETWEEN function can only contain numbers or a MIN and MAX function.",
                            errorWrongFunctionOpenBracket: "The \"(\" sign is missing after the function.",
                        }
                    }
                },
                form: {
                    lblSeed: "Value of seed",
                    lblIDSeq: "IDs in sequance",
                    lblFirstLine1: "The selected respondents",
                    lblFirstLine2: "",
                    lblSecondLine1: "Exactly",
                    lblSecondLine2: "respondents",
                    lblThirdLine1: "From",
                    lblThirdLine2: "to",
                },
                modal: {
                    colFirst: "The question",
                    colSecond: "The property",
                },
                btnAddNewVar: "Add new variable",
                btnSaveSample: "Save sample",
                btnAddAllUsers: "Load all users",
                btnResetFilters: "Reset filters",
                btnRefreshSample: "Refresh sample",
            },
            modules: {
                surveyName: "Survey",
                surveyText: "Add a survey to project",
                locName: "Location",
                locText: "Add the location module",
                actName: "Activity",
                actText: "Add the activity module"
            },
			logTable: {
				type: "Type of log",
				desc: "Description of log",
				cycles: "Cycle times",
				created: "Created date of log"
			},
            usersTable: {
                titleName: "Name",
                titleEmail: "Email",
                titleRemove: "Remove"
            },
            surveyTable: {
                titleName: "Name",
                titleSurvey: "Select the survey"
            },
            triggerTable: {
                titleName: "The name of template",
                titleTrigger: "Select a trigger",
                titleModuleName: "Relevant module",
                titleOperationName: "Trigger operation",
            },
            validating: {
                name: "The name of the project must be given, and must be at least 5 characters",
                acronym: "The acronym of the project must be given, and must be at least 2 characters",
                endDate: "Please add the finish date of the project",
                endTime: "Please add the finish time of the project",
                startDate: "Please add the start date of the project",
                startTime: "Please add the start time of the project",
                desc: "The content of the project must be given, and must be at least 10 characters",
            },
            messages: {
                newSuccess: "The new project has been successfully saved.",
                updateSuccess: "Successfully modified the project data."
            },
            triggers: {
                tabs: {
                    time: "Setting the time",
                    location: "Setting the location",
                    notification: "Setting the notification",
                },
				times: {
					set: "setting",
					withTimeSlot: "with time slot",
					withCustomTimeSlot: "with customized time slot",
					default: "Default settings",
					timeSlot: "Time slots",
					startTimeSlot: "Start time slot",
					endTimeSlot: "End time slot",
					color: "Color",
					dateStart: "Start date",
					timeStart: "Start time",
					dateEnd: "End date",
					timeEnd: "End time",
					loadTemp: "Load a template",
					saveTemp: "Save a template",
					removeRole: "Remove extisting role based on dates and times",
					savedTriggers: "Saved triggers",
					setNewTrigger: "Set a new trigger",
					noTriggerSetting: "No saved trigger role available",
				},
                nots: {
					addPlace: "Please set a location...",
					searchMap: "Please set a location on the map",
					zoomDefault: "Zoom to default size",
                    blockTitle1: "Trigger selection",
                    blockTitle2: "Setting up the notification",
                    blockTitle3: "Already recorded notifications",
                    selectATrigger: "Choose a trigger",
                    beforeNotification: "Notification before the trigger starts",
                    inActionNotification: "Notification during trigger",
                    afterNotification: "Notification after the end of the trigger",
                    beforeTime: "How much before?",
                    inActionTime: "How many times to repeat?",
                    afterTime: "How much after?",
                    pushNoti: "Mobile Push notification",
                    emailNoti: "Email notification",
                    noNotiText: "No notification yet recorded",
                    addNewTriggerBtn: "I want to set up a new trigger",
                    withEnMinute: "with {minute} minutes",
                    withTimes: "with times",
                    setTimeTrigger: "time trigger setting",
                    setLocationTrigger: "location trigger setting",
                    setupNotification: "notification set",
					form: {
						titleUpTime: "Minimum working time for the trigger",
						titleAddPlace: "Add location",
						titleShortDesc: "Short description",
						titleDefaultSet: "Trigger settings",
						titleTriggerType: "Set the trigger type",
						titleBetweenTime: "Minimum time between two triggers:",
						titleDistanceFrom: "Distance from the point",
						titleLocationMove: "The location is moveable",
						titleSavedLocations: "Locations added",
						btnNewLocation: "Add new trigger",
						textLocation: "location trigger",
						textNoLocation: "There is no available location",
						phOnlyNumber: "You can only enter numbers in the field",
					}
                }
            },
			diags: {
				title: "Diagnostics of the project",
				desc: "Display the diagnostics for the selected project. The most recent data are at the top of the table!",
				table: {
					name: "Responder neve",
					diagType: "Type of diagnostic",
					diagDesc: "Description of diagnostic",
					created: "Created date",
				}
			},
			disabled: "You do not have any permissions for this project!",
            btnImport: "Import respondents",
			btnAddNewUser: "Add new respondent",
            btnSamplingOpen: "Open sampling editor",
            btnSamplingClose: "Close sampling editor",
			btnCreateNewExport: "Create a new CSV export file",
            createNewProjectText: "Create a new project",
            processNewProjectText: "Creation in progress...",
        },
        survey: {
			tabs: {
				viewer: "View",
				designer: "Designer",
				settings: "Settings",
				flowchart: "Flowchart",
				variables: "Variables"
			},
            titles: {
                list: "Survey Editor",
                create: "Create a survey",
                defaults: "Default datas of new survey",
                untitle: 'Untitled text',
                undesc: 'Untitled description',
                unkey: "Enter a variable name or the system will generate one",
                selectType: "Select a question type and add new question",
				defaultSurveySettings: "Default settings of the survey",
            },
			surveyTypes: {
				traditional: "Traditional survey type",
				traditionalDesc: "Here you can edit general surveys where all the functionality can be used.",
				register: "Registration survey type",
				registerDesc: "Here you can edit registration questionnaires to link users to projects.",
				panel: "Panel survey type",
				panelDesc: "Here you can edit panel surveys to can add new respondents to your database."
			},
            texts: {
                list: "Below are the surveys currently running.",
                create: "You can use the editor below to compose your questionnaire. You can use the drag&drop technique to change the order.",
                selector: "Select the type of survey you would like to edit below",
				autoTranslateText: "If a survey is multilingual, the system can automatically translate the questions and answers into the other languages. This function is enabled by default. If you do not want to use it, you can turn it off.",
				autoTranslateTitle: "I turn on the automatic translation",
				autoTranslateSuccess: "The text has been successfully translated automatically.",
            },
			labels: {
				answerName: "Enter the answer option",
				answerLabel: "Label of the answer",
			},
            hints: {
				notRemove: "It cannot be deleted because it belongs to at least one project.",
                addNewQuestion: "Add new question",
				successSettings: "You have successfully setted the survey. The designer tab is now active.",
				warningDefaultCode: "Attention! Please change the question variable, because it is in default state. Allowed characters: letter, number and _ character.",
				warningDefaultSurveyName: "Attention! The name of the survey must be provided.",
				warningDefaultSurveyCode: "Attention! The short name of the survey must be changed, because it is the default.",
				warningDefaultAddedCode: "Attention! Please change the name and variable of the question, because it is copied from an existing question.",
				warningSurveySpecialCodes: "Attention! The abbreviation of the survey can only contain letters and numbers!",
				warningWrongCode: "Please do not use more than 2 _ characters in the variable!",
				errorCodeExists: "The given code already exists, so please choose another one!",
				errorQuestionType: "The parameter specified in the URL does not match the type of the survey!",
            },
            modals: {
                title: "Confirm the deletion of a selected survey",
                text: "Are you sure you want to delete this survey?",
                warningTitle: "Attention! The operation cannot be used!",
                warningText: "The selected survey currently belongs to an active project and cannot be deleted. If you want to delete the survey, delete it from the project first.",
				changeTypeText: "If you continue, your settings and the responses for this question will be deleted. This process can not be revised.",
				changeTypeTitle: "Warning! You are about to change the type of your question.",
                removeAnswerTitle: "Confirm the deletion of the selected answer",
                removeQuestionTitle: "Confirm the deletion of the selected question",
                removeQuestionText: "Sure you want to delete this question?",
                removeAnswerText: "Sure you want to delete this answer?",
            },
			options: {
				title: "Options",
				required: "Required",
				multiple: "Multiple selection",
				randomize: "Randomize",
				other: "Other option",
				dontknow: "Don't know",
				notanswer: "Prefer not to answer",
			},
			questionTypes: {
				radio: "Radio",
				checkbox: "Multiple choice",
				longText: "Long text",
				shortText: "Short text",
				number: "Number",
				email: "Email",
				phoneNumber: "Phone number",
				date: "Date",
				time: "Time",
				dateTime: "Date & Time",
			},
			settingMenus: {
				default: "Default settings",
				view: "View",
				import: "Import",
				export: "Export",
				chooseMenu: "Please choose a menu",
			},
			fullTypes: {
				blockChoices: "Choices",
				blockRatings: "Ratings & rankings",
				blockBlocks: "Blocks",
				blockTexts: "Texts",
				blockDates: "Dates & Times",
				blockUploads: "Uploads",
				blockContacts: "Contact infos",
				radio: "Radio",
				checkbox: "Multiple choice",
				longText: "Long text",
				shortText: "Short text",
				number: "Number",
				email: "Email",
				phoneNumber: "Phone number",
                website: "Website",
				date: "Date",
				time: "Time",
				dateTime: "Date & Time",
                pictureChoice: "Picture choice",
                yesNo: "Yes / No",
                matrix: "Matrix",
                opinionScale: "Opinion scale",
                likertScale: "Likert-scale",
                ranking: "Ranking",
                rating: "Rating",
                textBlock: "Text block",
                pictureBlock: "Picture block",
                videoBlock: "Video block",
                soundBlock: "Sound blokk",
                fileUpload: "File upload",
                videoUpload: "Video upload",
                photoUpload: "Picture upload",
                soundUpload: "Sound upload",
			},
			types: {
				numInt: "Integer",
				numReal: "Real number",
				numLabel: "Select the type of number you want to enter",
				labelName: "Name of label",
				textLabel: "Enter the max length of chars",
				phoneLabel: "Choose which country code we validate the phone number against",
				textWarning: "Max chars: {count}",
				textNoSettings: "There are no other options for this question type.",
			},
            settings: {
                main: {
                    phName: "The survey's name (max. 100 characters)",
                    phAcro: "The survey's acronym (max. 20 characters)",
                    phDesc: "The survey's description (max. 255 characters)",
                    surveyName: "Enter your survey's name",
                    surveyAcro: "Enter the short name of your survey, which should be unique",
                    surveyDesc: "Enter your survey's description",
                    surveyLang: "Select the languages in which you want to use the survey",
                },
                validating: {
                    surveyName: "Please enter the name of your survey in min 5, max. 100 characters",
                    surveyAcro: "Please enter the short name of your survey, which should be unique to min. 3, max 20 characters",
                    surveyDesc: "Please enter the description of your survey in min 10, max. 255 characters",
                    codeAlreadyExists: "This code already exists in database.",
					codeExceptsChars: "There are characters in the given code that are not allowed.",
                },
            },
            dropdown: {
                name: "Functions",
                add: "Add a new question",
                set: "Settings",
                del: "Delete"
            },
            answers: {
                text: 'Short text answer',
            },
			errors: {
				server: 'Server side error',
				wrongAddedCode: 'You cannot add a question if you have already done so, but the question name and question code have not been rewritten.',
				wrongCopiedCode: 'In case you cannot copy a question when you have already done so, but the question name and question code have not been rewritten.',
			},
            btnCopy: "Copy the Survey",
			allTypes: "All question types",
            defaultDesc: "Untitled description...",
            defaultTitle: "Untitled title...",
			questionValue: "Question value",
			btnAddNewSection: "Add new section",
            createNewSurveyText: "Create a new survey"
        },
        articleeditor: {
            titleName: "Title of Article",
            statusName: "Status of Article",
            titles: {
                list: "Article Editor",
                edit: "Edit an article",
            },
            descs: {
                list: "Below are the articles added to the system.",
                edit: "Use the form below to edit the Article content.",
                upload: "If you want, you can upload a new picture for the article.",
                phUpload: "Specify a png or svg file to modify the article's photo.",
            },
            messages: {
                errorEditor: "The question and the answer must be given in English and Hungarian.",
                uploadSuccess: "The image file upload has been successfully.",
                updateSuccess: "The update of article has been successfully implemented.",
                updateStatusSuccess: "The status of article has been successfully changed.",
            },
        },
        faqeditor: {
            titles: {
                add: "Add a New FAQ",
                list: "FAQ Editor",
                edit: "Edit a FAQ",
            },
            descs: {
                add: "Use the form below to add a new FAQ to the system.",
                list: "Below is the FAQ content added to the system so far.",
                edit: "Use the form below to edit the FAQ content."
            },
            form: {
                phId: "Enter the short identifier of the FAQ, such as: question-one",
                phTitle: "Enter your question (min 5 characters)",
                phDesc: "Enter your answer (min 10 characters)",
                labelId: "FAQ ID:",
                labelTitle: "The question:",
                labelDesc: "Answer for the question:",
                labelActive: "Set state the FAQ"
            },
            messages: {
                errorEditor: "The question and the answer must be given in English and Hungarian.",
                saveSuccess: "The save has been successfully implemented.",
                deleteSuccess: "The delete has been successfully implemented.",
                updateSuccess: "The update has been successfully implemented.",
                updateStatusSuccess: "The status has been successfully changed.",
            },
            modal: {
                title: "Confirm the deletion of a selected FAQ",
                text: "Are you sure you want to delete this FAQ?"
            },
            faqIdName: "FAQ ID",
            faqTitleName: "The question",
            faqActivated: "State of FAQ",
        },
        octres: {
            titleName: "The name of researcher",
            titles: {
                list: "Octopus Researchers",
                edit: "Edit a researcher",
                fileUp: "If you want, you can upload a new picture for the researcher.",
                fileAct: "Actually picture."
            },
            descs: {
                list: "Below we present the researchers at Octopus.",
                edit: "Use the form below to edit a researcher's datas."
            },
            form: {
                phpPos: "Enter the position of researcher (min 5 characters)",
                phTitle: "Enter the name of researcher (min 5 characters)",
                phUpload: "Specify a png or svg file to modify the researcher's photo",
                phDesc: "Enter the description of researcher (min 10 characters)",
                labelPos: "The position of researcher:",
                labelTitle: "The name of researcher:",
                labelDesc: "The description of researcher:"
            },
            messages: {
                errorEditor: "The datas must be given in English and Hungarian.",
                uploadSuccess: "The image file upload has been successfully.",
                updateSuccess: "The update data of researcher has been successfully implemented.",
            },
        },
        nlsubs: {
            title: "Newsletter Subscribers",
            text: "Below are the newsletter subscribers who have done so from our frontend site.",
            table: {
                email: "Email address",
                createdat: "Registered date"
            }
        },
        ressubs: {
            title: "Researcher Subscribers",
            text: "Below are the researcher subscribers who have done so from our frontend site.",
            table: {
                fname: "First name",
                lname: "Last name",
                email: "Email address",
                phone: "Phone number",
                createdat: "Registered date"
            }
        }
    },
    hu: {
        comingSoon: "Hamarosan...",
        goToTop: "A lap tetejére",
        appVersionTitle: "Verzió",
        btnOk: "Ok",
        btnAccept: "Rendben",
        btnAdd: "Hozzáad",
        btnBack: "Vissza",
        btnSave: "Mentés",
        btnSend: "Küldés",
        btnShow: "Megjelenítés",
        btnEdit: "Szerkesztés",
        btnView: "Megtekintés",
        btnAdded: "Hozzáadva",
        btnClose: "Bezár",
		btnRoles: "Szerepkörök",
        btnCreate: "Létrehozás",
        btnUpload: "Feltöltés",
        btnUpdate: "Módosítás",
        btnDelete: "Törlés",
        btnCancel: "Mégse",
        btnExport: "Export",
		btnFilter: "Szűrés",
		btnSearch: "Keresés",
		btnDownload: "Letöltés",
        btnSaveSend: "Mentés és küldés",
        btnSettings: "Beállítások",
        btnExporting: "Exportálás",
        btnLeavePage: "Elhagyom az oldalt",
        btnXlsExport: "Excel Export",
        btnPdfExport: "PDF Export",
		btnShowResults: "Eredmények",
		btnFilterCancel: "Szűrések törlése",
		btnShowLogResults: "Logok megtekintése",
		btnShowDiagResults: "Diagnosztikák megtekintése",
        btnNewPwdRequired: "Új jelszó igénylése",
        btnBackToTheLogin: "Vissza a bejelentkező oldalra",
		aServiceIsNotWorking: "Sajnáljuk, de ez a szolgáltatás jelenleg nem működik.",
        btnBackToTheMainPage: "Vissza a főoldalra",
        validateAttentionText: "Figyelem! Az alábbi problémá(k)ba ütköztem:",
        validateErrorNameText: "A névben használj szóközt, s legalább 5 karakterből kell állnia.",
        validateNullNameText: "A név mező nem lehet üres",
        validateNullRoleText: "Kérlek válassz egy felhasználói csoportot",
        validateErrorEmailText: "Kérlek valós email címet adj meg",
        validateNullEmailText: "Kérlek add meg az email címed",
        validateErrorPasswordText: "A jelszónak 8-40 karakter közt kell lennie, legyen benne kis- és nagybetű, illetve szám és speciális karakter.",
        validateNullPasswordText: "Kérlek add meg a jelszavad",
        validateNotEqualPasswordsText: "A megadott jelszavak nem egyeznek",
        validateLoginWrongAccount: "Hibás email cím vagy jelszó",
        validateForgotPasswordWrongText: "Ehhez az email címhez nem tartozik felhasználó.",
        defaults: {
			all: "Mindegyik",
			exit: "Kilépés",
			enter: "Belépés",
			content: "Tartalom",
			everyone: "Mindenki",
			initMaps: "Inicializálom a térképet, kérlek várj...",
            initializing: "Inicializálás, kérlek várj...",
            addAllFields: "Kérlek tölts ki minden mezőt",
            confirm: "Megerősítés",
            loading: "Betöltés, kérlek várj...",
            uploading: "Feldolgozás alatt, kérlek várj...",
			text403: "A kért oldal megtekintéséhez nincs jogosultságod.",
			title403: "Jogosultsági hiba",
			text404: "A keresett oldal nem található.",
			title404: "Hoppá, hiba történt!",
            required: "Kötelező",
			registerDate: "Regisztráció dátuma",
			attention: "Figyelem!",
            operation: "Művelet",
            updatedField: "Módosítás dátuma",
			uploadPHImageText: "Válassz egy képfájlt (svg, jpg, png)",
            footerText: "Társadalomtudományi Kutatóközpont",
            pleaseSelect: "Kérlek válassz...",
			serverError: "Szerver oldali hiba",
			latitude: "Szélesség",
			longitude: "Hosszúság",
			fieldMeterText: "méter",
			fieldMinuteText: "perc",
			fieldSecondText: "másodperc",
			gotoTheMainPage: "Vissza a főoldalra",
            wrongFileFormat: "Nem megfelelő fájl formátum!",
			wrongScreenSize: "A rendszer használhatához ajánlott felbontás: HD (1920x1080px)",
			failUpdate: "Kérlek ellenőrizd, hogy megadtál-e minden kötelező adatot!",
            successSaveProcess: "Sikeres mentés.",
			successCreateProcess: "Sikeres létrehozás.",
			successUpdateProcess: "Sikeres módosítás.",
			successDeleteProcess: "Sikeres törlés.",
			successDraggedProcess: "Sikeres mozgatás.",
			errorMultipleSelectNotNull: "A legördülő mezőnek legalább egy elemet tartalmaznia kell!",
			cannotLogoutFromNewSurvey: "Figyelem! Amíg egy új kérdőív alapadatait szerkeszted, nem hagyhatod el a rendszert!",
			cannotLogoutFromNewProject: "Figyelem! Amíg egy új projekt alapadatainak szerkesztésekor nem történik mentés, nem hagyhatod el a rendszert!",
			filters: {
				filterText: "Kérlek adj meg egy szűrőfeltételt...",
				loadedNums: "Felhasználók száma: ",
				loadedResps: "Válaszadók száma: ",
				elemPerPage: "elem / oldal",
			}
        },
        days: {
            monday: "Hétfő",
            tuesday: "Kedd",
            wednesday: "Szerda",
            thrusday: "Csütörtök",
            friday: "Péntek",
            saturday: "Szombat",
            sunday: "Vasárnap"
        },
        statuses: {
            active: "Aktív",
            deactive: "Nem aktív"
        },
        regVerifier: {
            name: "Regisztrációs kód ellenőrzése",
            process: "Ellenőrzés, Kérlek várj...",
            successMessage: "A regisztrációd sikeresen megerősítettük!"
        },
        usersTable: {
            titleName: "Név",
            titleEmail: "Email",
            titleAddTo: "A projekthez adom",
            titleRegTo: "Rergisztrációs email kiküldése"
        },
        login: {
            title: "Bejelentkezés",
            text: "Add meg az alábbi adatokat a bejelentkezéshez.",
            lblEmail: "Add meg az email címed:",
            lblPassword: "Add meg a jelszavad:",
            reLoginText: "Lejárt a bejelentkezésre szánt idő, ezért kérlek jelentkezz be újból a fiókodba az email címed és jelszavad használatával",
            phEmail: "Email címed",
            phPassword: "Jelszavad",
            btnCheck: "Ellenőrzés...",
            btnLogin: "Belépek a rendszerbe",
            btnForgotPass: "Elfelejtettem a jelszavam",
            validating: {
                wrongAuth: "A bejelentkezési adataid nem helyesek, ezért kérlek próbáld meg újra.",
                errorEmail: "Kérlek valós email címet adj meg",
                errorPassword: "A jelszónak 8-40 karakter közt kell lennie, legyen benne kis- és nagybetű, illetve szám és speciális karakter.",
                errorSimplePassword: "Kérlek érvényes jelszót adj meg",
				userRespondent: "Figyelem! Jelenleg válaszadói jogosultsággal rendelkező userek nem léphetnek be a rendszerbe!",
            }
        },
        forgotPassword: {
            title: "Elfelejtett jelszó",
            text: "Add meg a valós email címed, amire elküldhetjük az új jelszót",
            process: "Email küldése, kérlek várj...",
            successMessage: "Sikeresen frissítettük a jelszavad, amelyet e-mailben kiküldtünk."
        },
        breadcrumbs: {
            home: "Főoldal",
        },
        dashboard: {
			title: "Vezérlőpult",
			desc: "Az alábbiakban összefoglaljuk a felhasználóhoz tartozó jelenlegi projekteket és kérdőíveket.",
			cardProjects: "Aktív projektek",
			cardSurveys: "Kérdőívek",
			cardPanelMembers: "Paneltagok",
			lastModified: "Utolsó módosítás",
			project: "Projekt",
			createdDate: "Létrehozás dátuma",
			hasNoProject: "Még nincs projektje",
			blockProjectTitle: "Aktuális projektek",
			blockProjectDesc: "Az alábbi blokkban láthatóak az aktuálisan futó projektek.",
			blockSurveyTitle: "Aktuális kérdőívek",
			blockSurveyDesc: "Az alábbi blokkban láthatóak az aktuálisan futó kérdőívek.",
        },
        headerMenu: {
            dashboard: "Vezérlőpult",
            settings: "Beállítások",
            logout: "Kijelentkezés"
        },
        sidebarMenu: {
            mainmenu: "Főmenü"
        },
        settings: {
            title: "Beállítások"
        },
        notifications: {
            btnAdd: "Új értesítés",
            titles: {
                list: "Email értesítések",
                edit: "Email értesítés szerkesztése",
            },
            descs: {
                list: "Az alábbiakban láthatóak az eddig kiküldött regisztrációs email értesítők listája",
                edit: "Az alábbi form segítségével szerkesztheted az email értesítésedet, amelynek segítségével értesülhetnek a felhasználók az új regisztrációs igényről.",
            },
            table: {
                name: "Értesítés neve",
                type: "Értesítés típusa",
                updated: "Módosítva",
                hasUsers: "Meglévő felhasználók",
                newUsers: "Új felhasználók",
                surveyName: "Regisztrációs kérdőív neve",
                sendElemNum: "Kiküldött kérések száma",
                answeredElemNum: "Megválaszolt kérések száma",
            },
            form: {
                lbName: "Az értesítés neve",
                lbLang: "Válaszd ki a nyelvet",
                lbType: "Válaszd ki az értesítés típusát",
                lbSurvey: "Válaszd ki a regisztrációs kérdőívet",
                lbAddresses: "Add meg az email címeket",
                lbEmailTemp: "Válaszd ki az email sablont",
                phName: "Add meg az értesítés nevét",
                phType: "Válaszd ki, hogy az értesítés milyen felhasználóknak küldődjön el",
                phSurvey: "Válaszd ki a regisztrációs kérdőívet",
                phAddresses: "Add meg az email címeket egymás alá ENTER-rel elválasztva.",
            },
            types: {
                hasuser: "Meglévő felhasználók",
                newuser: "Új felhasználók",
            },
            validating: {
                name: "Kérlek add meg az értesítés nevét! A lehetséges karakterek száma: 5-100",
                type: "Kérlek add meg, hogy új vagy már meglévő felhasználóknak szeretnél értesítést küldeni!",
                regsurvey: "Kérlek válassz egy regisztrációs kérdőívet!",
            },
            messages: {
                saveSuccess: "Az értesítés mentése sikeresen megtörtént.",
            }
        },
        clients: {
            titles: {
                list: "Ügyfelek",
                edit: "Ügyfél adatainak szerkesztése"
            },
            descs: {
                list: "Az alábbiakban a rendszerhez eddig hozzáadott ügyfelek kerülnek bemutatásra.",
                edit: "Az alábbi űrlap segítségével szerkesztheted az ügyfél adatait. A *-gal megjelölt mezők kitöltése kötelező."
            },
            form: {
                lbName: "Az ügyfél neve (*)",
                lbCode: "Az ügyfél egyedi azonosítója (*)",
                lbEmail: "Az ügyfél email címe (*)",
				lbLang: "Az ügyfél által használt alapértelmezett nyelv",
                lbPhone: "Az ügyfél telefonszáma",
                lbAddress: "Az ügyfél címe",
                lbConName: "A kapcsolattartó neve",
                lbConEmail: "A kapcsolattartó email címe",
                lbConPhone: "A kapcsolattartó telefonszáma",
                lbConPos: "A kapcsolattartó pozíciója",
                lbRegister: "Az ügyfél regisztrációs kérdőíve",
                lbActive: "Ügyfél aktiválás státusza",
                phName: "Add meg az ügyfél nevét",
                phCode: "Add meg az ügyfél egyedi azonosítóját",
                phEmail: "Add meg az ügyfél email címét",
				phLang: "Válassz egy nyelvet a lehetőségek közül",
				phMoreLang: "Válassz legalább egy nyelvet a lehetőségek közül",
                phPhone: "Add meg az ügyfél telefonszámát",
                phAddress: "Add meg az ügyfél címét",
                phConName: "Add meg a kapcsolattartó nevét",
                phConEmail: "Add meg a kapcsolattartó email címét",
                phConPhone: "Add meg a kapcsolattartó telefonszámát",
                phConPos: "Add meg a kapcsolattartó pozícióját",
                phRegister: "Válassz egy regisztrációs kérdőívet (nem kötelező)",
                btnGenerate: "Ügyfélkód generálása",
                btnGenerating: "Generálás folyamatban..."
            },
            modals: {
                deleteDesc: "Biztosan törölni szeretnéd a kiválasztott ügyfelet? Ha igen, akkor a hozzá tartozó felhasználók se belépni, se projektet indítani vagy egy projektben részt venni nem fognak tudni.",
                deleteTitle: "A kiválasztott ügyfél törlése",
                warningDesc: "Új kódot generáltál az ügyfél számára. Ha most elhagyod az oldalt, a módosítások nem kerülnek mentésre. Ha el szeretnéd menteni a módosításokat, zárd be ezt az ablakot, és nyomd meg a Módosítás gombot.",
                warningTitle: "Biztosan el akarod hagyni az oldalt?",
                generateTitle: "Új ügyfélkód generálása",
                generateDesc: "Biztosan szeretnél generálni új ügyfélkódot? A kód megváltoztatása befolyásolhatja a további folyamatokat.",
                statusTitle: "Ügyfél státuszának módosítása",
                statusOnDesc: "Ha újra bekapcsolod, az ügyfél és felhasználói újra be tudnak jelentkezni, és az ügyfél projektjei ismét elérhetővé válnak",
                statusOffDesc: "Ha kikapcsolod, akkor az ügyfélhez tartozó felhasználók se belépni, se projektet indítani vagy egy projektben részt venni nem fognak tudni.",
            },
            messages: {
                deleteSuccess: "Az ügyfél törlése sikeresen megtörtént.",
                updateSuccess: "Az ügyfél adatainak módosítása sikeresen megtörtént.",
                updateStatusSuccess: "Az ügyfél státuszának módosítása sikeresen megtörtént.",
            },
            validating: {
                name: "Add meg az ügyfél nevét (kötelező adat)",
                email: "Add meg az ügyfél email címét, amin keresztül elérhető",
                code: "Add meg az ügyfél egyedi azonosítóját - kattints az ügyfélkód generálása gombra"
            },
            clientName: "Ügyfél neve",
            clientEmail: "Ügyfél email címe",
            clientStatus: "Ügyfél státusza",
            btnAddNewClient: "Új ügyfél hozzáadása"
        },
        users: {
            addUser: "Új felhasználó hozzáadása",
            editUser: "Felhasználó szerkesztése",
            userProfile: "Felhasználói profil szerkesztése",
            userForProject: "További tulajdonságok megadása a(z) {pname} nevű projekt számára.",
            userList: "Felhasználók kezelése",
            userSubTitle: "Töltsd ki az űrlap összes elemét, majd nyomd meg a mentés gombot.",
			successSave: "Az új felhasználó rögzítve lett a rendszerben.",
			successUpdate: "A felhasználó adatai sikeresen módosultak.",
			successDelete: "A felhasználó sikeresen törölve lett a rendszerből.",
			wrongAuthDatas: "Mivel nem sikerült egyértelműen meghatározni a felhasználói jogaidat, ezért kérlek lépj be újra a rendszerbe!",
            userListSubTitle: "Az alábbi táblázatban láthatóak a rendszerben jelen lévő felhasználók. Jogosultságodnak megfelelően tudod az adatokat szerkeszteni/törölni.",
            userForProjectSubTitle: "Adj meg annyi adatot, amennyit csak tudsz, hogy a mintavételezési eljárásban minél nagyobb esélyekkel szerepelj.",
            editFormAgeTitle: "Életkor",
            editFormOptional: "Opcionális",
            editFormHomeTitle: "Lakhely",
            editFormNameTitle: "Név",
            editFormRightTitle: "A felhasználó jogosultsága",
			editFormRoleTitle: "A felhasználó szerepköre",
            editFormGenderTitle: "Nem",
            editFormClientTitle: "A felhasználó ehhez az ügyfélhez tartozik",
            editFormNationalityTitle: "Állampolgárság",
            editFormEmailTitle: "Email",
            editFormPasswordTitle: "Jelszó",
            editFormRePasswordTitle: "Jelszó újra",
			errorNameTitle: "A név mező nem lehet üres",
            modalTitle: "Kiválasztott felhasználó törlésének megerősítése",
            modalText: "Biztosan törölni szeretnéd a kiválasztott felhasználót?",
            phRights: "Kérlek válassz egy felhasználói csoportot",
			phRole: "Kérlek válassz egy szerepkört",
            phClient: "Kérlek válassz egy ügyfelet",
            phGender: "Add meg a nemed (opcionális)",
            table: {
                name: "Név",
                added: "Kutató hozzáadása a projekthez",
                registered: "Regisztráció dátuma",
				roles: "Szerepkör",
                rights: "Jogosultság",
                status: "Státusz",
				selectRole: "Szerepkör kiválasztása"
            },
            role: {
                res: "Kutató",
                user: "Válaszadó",
                admin: "Adminisztrátor",
            },
            validating: {
                name: "Kérlek add meg a neved! Lehetséges karakterek száma: 5-100",
                email: "Kérlek add meg a valós email címed! Lehetséges karakterek száma: 5-100",
                password: "A jelszavadban legyen legyen kis- egy nagybetű, szám és legalább egy speciális karakter. A jelszó hossza: 8-40 karakter. Mindkét jelszónak egyeznie kell!",
				passwordsAreEmpties: "Mindkét jelszó mező kitöltése kötelező!",
				noClient: "Az ügyfél kiválasztása kötelező",
				noRight: "A jogosultsági csoport kiválasztása kötelező",
				noRole: "Egy szerepkör kiválasztása kötelező"
            }
        },
		roles: {
			modals: {
				removeText: "Biztosan törölni szeretnéd a kiválasztott szerepkört?",
				removeTitle: "Szerepkör törlésének megerősítése",
			}
		},
        emails: {
            titles: {
                list: "Email sablonok",
                edit: "Email sablon szerkesztése",
                send: "Email küldése",
                show: "Email sablon megjelenítése",
                create: "Email sablonok létrehozása"
            },
            texts: {
                list: "Az alábbi táblázatban láthatóak az adatbázisban szereplő email sablonok, amiket levélküldéshez tudunk használni",
                edit: "Változtasd meg az alábbi űrlapon azt, amit szeretnél, majd nyomd meg a módosítás gombot",
                send: "Az alábbi űrlap segítségével ki tudod küldeni egy címzettnek a sablont teszt céljából",
                show: "Az alábbiakban látható az email sablon tartalma",
                create: "Töltsd ki az alábbi űrlap minden elemét, majd nyomd meg a mentés gombot"
            },
            table: {
                name: "A template neve",
                subject: "Az üzenet tárgya",
            },
            showDatas: {
                name: "A sablon neve",
                subject: "A sablon tárgya",
                content: "A sablon tartalma"
            },
            form: {
                labelEmail: "Kérlek adj meg egy email címet",
                labelName: "Add meg a sablon nevét",
                labelDelete: "Add meg, hogy a sablon törölhető-e vagy sem",
                labelDefault: "Add meg, hogy a sablon alapértelmezett-e vagy sem",
                labelSubject: "Add meg a levél tárgyát",
                labelContent: "Add meg a levél tartalmát",
                labelSendLang: "Válaszd ki, hogy melyik nyelven szeretnéd elküldeni a sablont",
                labelSelectLang: "Válaszd ki, hogy melyik nyelven szeretnéd megjeleníteni a sablont",
                phEmail: "Email cím",
                phName: "A sablon neve",
                phSubject: "A levél tárgya",
                phContent: "A levél tartalma..."
            },
            validating: {
                name: "A sablon nevét kötelező megadni, s min. 5 karakternek kell lennie",
                subject: "A levél tárgyát kötelező megadni, s min. 5 karakternek kell lennie",
                content: "A levél tartalmát kötelező megadni, s min. 10 karakternek kell lennie",
            },
            modal: {
                title: "A kiválasztott email sablon törlésének megerősítése",
                text: "Biztosan törölni szeretnéd ezt a sablont?"
            },
            btnCreateText: "Új email sablon létrehozása",
            successDeleted: "Sikeresen töröltem az email sablont.",
            successCreated: "Sikeresen létrehoztam az email sablont.",
            successUpdated: "Sikeresen módosítottam az email sablont.",
            successSendEmail: "Az email sikeresen elküldve."
        },
        profile: {
            menuHeader: "Fiók",
            menuUpdates: "Frissítések",
            menuMessages: "Üzenetek",
            menuTasks: "Feladatok",
            menuComments: "Megjegyzések",
            menuProfile: "Profil",
            menuSettings: "Beállítások",
            menuLockAccount: "Account felfüggesztése",
            menuLogout: "Kijelentkezés",
        },
        peditor: {
            titles: {
                list: "Projekttervező",
                create: "Új projekt létrehozása",
                edit: "Projekt szerkesztése",
                result: "Egy projekt eredményei",
				logresult: "A projekthez tartozó logok",
                sampling: "Mintavételezés",
                addDefault: "Kérlek add meg az alapadatokat",
                addUsers: "Kérlek adj felhasználókat a projekthez",
                addModules: "Kérlek adj modulokat a projekthez",
                addTriggers: "Állítsd be triggereket a kiválasztott modulhoz",
                addResearcher: "Kérlek válassz kutatókat és szerepköröket a táblázatból",
				addRespondent: "Adj hozzá a projekthez egy új válaszadót",
                flipTitleError: "A modul nincs beállítva",
                flipTextOk: "A modul beállítása sikeresen megtörtént. Adj triggereket a modulhoz a további finomhangolások miatt.",
                flipTextFullOk: "A modul beállítása sikeresen megtörtént. A továbbiakban már nincs más fontos dolgod.",
                flipTextError: "A modul még nincs beállítva, amit megtehetsz a kék ikonra való kattintás során.",
				exportTitle: "Eddig exportált fájlok",
            },
            texts: {
                list: "Az alábbiakban az aktuálisan futó projektek láthatóak. A passzív projekteket tartalmazó blokk össze van csukva, de bármikor kinyithatod, ha látni szeretnéd, milyen projektjeid voltak eddig.",
                edit: "Az alábbi űrlap segítségével tudod módosítani a projektet",
                create: "Az alábbi űrlap segítségével tudod összeállítani a projektet",
                result: "Az alábbiakban láthatóak és letölthetőek a projekt eredményei",
				logresult: "Az alábbiakban láthatóak felhasználóként a projekthez tartozó logok",
                actives: "Aktív modulok",
                passives: "Passzív modulok",
				addAName: "Adj meg egy nevet...",
				addFilterText: "Add meg a keresési feltételt",
                sampling: "A vizuális szerkesztő segítségével állítsd össze a saját csapatodat.",
                addUserText: "A táblázatban láthatod a hozzáadott felhasználókat",
                addedUserNum: "Minta: {count} válaszadó",
                lastModified: "Utolsó módosítás: {modified}, {name}",
                addModuleText: "A jobb oldalon lévő zöld plusz ikon segítségével tudsz hozzáadni modulokat",
                addTriggerText: "Az alábbi panelen tudod beállítani a modulhoz a szükséges triggereket",
                addedResearchers: "{count} meghívott kutató",
                addResearcherText: "Az alább megjelenő táblázatból ki tudsz választani egy vagy több kutatót. Amint egyet kiválasztottál, be tudod állítani, hogy az adott projektben milyen szerepkörei lehetnek.",
                dataCollection: "Adatgyűjtés",
                setViewPageText: "Váltás másik nézetre",
                activeProjectText: "Aktív projektek",
                passiveProjectText: "Passzív projektek",
                addTriggerShowText: "Az alábbi naptárban láthatod, hogy a milyen triggerek lettek beállítva",
                addNewModuleText: "Új modul hozzáadása",
                deleteModuleText: "Modul törlése",
                surveyModuleText: "Kérdőív modul beállítása",
                locationModuleText: "Helyszín modul beállítása",
				wrongAddedResearcher: "Ki kell választani a felhasználó jogosultsági szintjét is!",
				smsModuleText: "SMS modul beállítása",
				callModuleText: "Hívás modul beállítása",
				audioModuleText: "Hang modul beállítása",
				photoModuleText: "Kép modul beállítása",
				videoModuleText: "Videó modul beállítása",
				eventsModuleText: "Esemény lekérdező modul beállítása",
				appUsageModuleText: "App használati modul beállítása",
                errorModuleText: "Probléma van a modul beállításaival, kérlek ellenőrizd!",
                triggerNumberText: "A modulhoz hozzáadott triggerek száma",
                defaultActiveText: "Ha aktív...",
                defaultTriggerText: "Akkor az történjen, hogy ",
                selectedActiveText: "Ha aktív a(z) ",
                addTriggerToModuleText: "Trigger hozzáadása a modulhoz",
                selectModuleToTriggerText: "Kiválasztandó modul: ",
				expiredprojectInfoText: "Ez már egy lejárt projekt, így a beérkező adatok már nem ellenőrizhetők.",
				lastUploadDate: "A válaszadó legutolsó feltöltésének pontos dátuma: ",
				noUploadData: "Figyelem! A válaszadótól még nem jött semmilyen adat!",
				criteriaDown: "Figyelem! Túl sok találat van erre a szűkítési feltételre, emiatt szükséges tovább finomítani a keresési feltételeket.",
				cycleStartAndEnd: "A mérési ciklus kezdete és vége: ",
				loadedRespondentsNum: "A betöltött válaszadók száma: {count} fő.",
				exportText: "Az alábbi táblázatban látható, hogy eddig hány db fájl volt exportálva. Ha nem akarsz új exportot, akkor a már meglévőket is letöltheted. Ha új exportot szeretnél készíteni, akkor nyomd meg az alábbi nyomógombot.",
				exportProcess: "Az export folyamat az adatbázisban tárolt rekordok számától függően hosszabb ideig is eltarthat!",
				exportBroadcastProcess: "Az export folyamatot elindítottuk, így a munkát folytathatod tovább, mert ha elkészült, akkor kapsz róla értesítést.",
				noLocationData: "Jelenleg nincs az adatbázisban ehhez a projekthez rendelt lokációs adat, így a hőtérkép jelenleg nem használható.",
            },
			views: {
				role: "Szabály nézet",
				calendar: "Naptár nézet",
				timeLine: "Idővonal nézet",
			},
            form: {
                labelAcro: "A projekt rövidítése",
                labelName: "A projekt neve",
                labelDesc: "A projekt belső leírása. Még elérhető: {count} karakter.",
				labelLang: "A projekt ezeken a nyelveken elérhető",
				labelAppDesc: "A projekt mobil appon belül megjelenő leírása",
                labelEndDate: "A projekt befejezésének dátuma",
                labelStartDate: "A projekt kezdetének dátuma",
                labelRegSurveys: "A projek regisztrációs- vagy panel kérdőíve",
				labelUploadProjectImage: "A projekthez tartozó kép feltöltése",
                phAcro: "A projekt rövidítése",
                phName: "A projekt neve",
                phDesc: "A projekt leírása",
				phSurv: "Válassz egy kérdőívet...",
				phPlat: "Válassz legalább egy platformot",
				phImageUpload: "Add meg a projekthez tartozó képet",
                btnAddToProject: "A projekthez adom",
				phModuleImageUpload: "Add meg a modul ikon- vagy képfájlját",
            },
            modal: {
                title: "A kiválasztott projekt törlése",
                moduleTitle: "A kiválasztott modul törlése",
                surveyTitle: "Kérdőív hozzáadása a projekthez",
                uploadTitle: "CSV fájl feltöltése az új felhasználókkal",
				smsTitle: "Az SMS modul beállítása",
				callTitle: "A hívás modul beállítása",
				audioTitle: "A hang modul beállítása",
				photoTitle: "A kép modul beállítása",
				videoTitle: "A videó modul beállítása",
				eventTitle: "Az esemény lekérdező modul beállítása",
				appUsageTitle: "Az app használat modul beállítása",
                locationTitle: "Helyszínbeállítások hozzáadása a projekthez",
                activateTitle: "A kiválasztott projekt aktívvá tétele",
				dailyInfoTitle: "A napi adatok megmutatása",
                inactivateTitle: "A kiválasztott projekt passzívvá tétele",
                loadTriggerTitle: "Egy trigger sablon betöltése",
                loadGoogleMapsTitle: "Helyszínek a Google Maps-en",
                saveTriggerTitle: "A trigger beállítások mentése",
                deleteTriggerTitle: "A kiválasztott trigger sablon törlése",
                text: "Biztosan törölni szeretnéd a kiválasztott projektet?",
				urlPHText: "Az oldal neve, ahová szeretnéd beágyazni a kérdőívet",
                moduleText: "Biztosan törölni szeretnéd a kiválasztott modult a projetből?",
                surveyText: "Válassz egy kérdőívet az alábbi legördülőből",
				surveyType: "A kérdőív típusa:",
				surveyExpire: "A kérdőív lejárata:",
				surveyUsedNotif: "Értesítő üzenetben tölthető ki a kérdőív",
				surveyEmbed: "Embeddelhető más weboldalakba a kérdőív",
                uploadText: "Válassz egy fájlt:",
                uploadPHText: "Adj meg egy CSV fájlt...",
                uploadCancel: "Nincs importálás, mert a CSV-fájlban nem voltak új adatok.",
                uploadSuccess: "A CSV feltöltése és a felhasználók importja sikeres volt.",
				moduleAppText: "A modul infója, ami az appban jelenik meg:",
				moduleColorText: "A modul színe:",
				moduleShortText: "A modul rövid leírása:",
				moduleFontColorText: "A modul betűjének színe:",
                activateText: "Biztosan aktiválni szeretnéd ezt a projektet?",
                inactivateText: "Biztosan inaktiválni szeretnéd ezt a projektet?",
                loadTriggerText: "Válaszd ki az alábbi listából azt a sablont, amivel szeretnél dolgozni.",
                saveTriggerText: "Kérlek add meg a trigger sablon nevét:",
                surveyModuleText: "Add meg a kérdőív modul nevét (max 15 karakter)",
                confirmDeleteText: "Biztosan törölni szeretnéd a kiválasztott felhasználót a projektből?",
                confirmImportText: "Van legalább egy felhasználó a projekthez kapcsolva. Ha importálsz felhasználókat, akkor törlődni fognak a jelenlegiek.",
                locationModuleText: "Add meg a helyszín modul nevét (max 15 karakter)",
                surveyModuleLabel: "A kérdőív modul neve",
				frequencyLabel: "Az adatfelvétel gyakorisága",
                freqRecordingLabel: "Az adatrögzítés gyakorisága (perc vagy óra/órák)",
                freqRecordingText: "Megadható adatok pl: 60 VAGY 10:00; 11:00; 12:00 (stb.)",
				smsModuleLabel: "Az SMS modul neve",
				audioModuleLabel: "A hang modul neve",
				photoModuleLabel: "A kép modul neve",
				videoModuleLabel: "A videó modul neve",
				phoneModuleLabel: "A telefon használat modul neve",
				eventModuleLabel: "Az esemény lekérdező modul neve",
				appUsageModuleLabel: "Az app használat modul neve",
                locationModuleLabel: "A helyszín modul neve",
                deleteTriggerText: "Biztosan törölni szeretnéd a kiválasztott triggert?",
                deleteTriggerGroupText: "Biztosan törölni szeretnéd a kiválasztott trigger csoportot?",
                deleteTriggerDefaultText: "Biztosan törölni szeretnéd a kiválasztott triggert?",
				tabAddNewRespondent: "Új válaszadó hozzáadása",
				tabSelectRespondent: "Már a rendszerben szereplő válaszadó hozzáadása",
				addNewResponderName: "Add meg az új válaszadó nevét",
				addNewResponderEmail: "Add meg az új válaszadó email címét",
				phResponderName: "Itt adhatod meg az új felhasználó nevét",
				phResponderEmail: "Itt adhatod meg az új felhasználó email címét",
				phpResponderPass: "Add meg a válaszadó jelszavát (nem kötelező)",
				phpResponderRePass: "Add meg azt a jelszót, amit az előbb megadtál",
				lbEmailAddress: "Válaszd ki az email címet a listából",
				successAddNewResponder: "Sikeresen hozzáadtál egy új válaszadót a projekthez!",
				updateAddResponder: "Sikeresen hozzáadtál egy meglévő válaszadót a projekthez!",
				pleaseChooseAnEmail: "Kérlek válassz egy email címet a listából!",
				noMoreRespondents: "Nincs több hozzáadható válaszadó. Kérlek vegyél fel egy újat a másik fülön!",
				tableExportName: "A fájl neve",
				tableExportLastUpdated: "Az exportálás dátuma",
				tableExportDownload: "A fájl letöltése",
				canFilterExportText: "Mivel használtad a szűrőt, így lehetőséged van csak a szűrt adatokat exportálni.",
				gpsAccurary: "Kérlek állítsd be a GPS koordináták pontosságát",
				wrongFreqHourFormat1: "Az óra:perc formátumban mindkét érték csak számokat kaphat!",
				wrongFreqHourFormat2: "Az óra 0 és 23, míg a perc 0 és 59 közti értékeket kaphat csak!",
				wrongFreqHourFormat3: "Az óra és perc adatoknak mindig 2 számjegyűnek kell lenniük!",
				wrongFreqHourFormat4: "A megadható legkisebb perc az 1, míg a legnagyobb az 1440 (1 teljes nap)",
            },
			results: {
				projectStartEndDates: "A projekt kezdeti időpontja: {0}. A projekt befejezése: {1}",
				tabCheckDatas: "A beérkezett adatok ellenőrzése",
				tabDatasOnDiagram: "Az adatok grafikonon",
				resultDate: "A lekért időpont: {0}",
				exportDatas: "Moduladatok exportálása",
				resultDatas: "A felhasználó ennyi adatot küldött be ezen a napon: {0}",
				resultModuleName: "A modul neve: {0}",
				resultResponderName: "A válaszadó neve: {0}",
				missingUploads: "Mutasd azokat a válaszadókat, akik a héten még nem töltöttek fel semmit:",
				tableUser: "Válaszadó neve",
				tableNrcId: "NRCID",
				tableRegister: "Regisztráció ideje",
				tableEmailAddress: "Email cím",
				tableProgress: "Általános előrehaladás",
				tableDataProgress: "Heti adatok előrehaladása",
				textNoResult: "A megadott szűrési feltételeknek megfelelően nincs találat!",
			},
            sampling: {
                blocks: {
                    nameVars: "Változók",
                    nameStat: "Statisztika",
                    nameFilters: "Szűrők beállítása",
                    nameSampling: "További szűrések",
                    nameSelectedUsers: "Kiválasztott felhasználók",
                },
                codeeditor: {
                    optFilter: "Függvények",
                    optLogic: "Logikai operátorok",
                    lblCheck: "Vizsgálat",
                    helps: {
                        txtIs: "Az IS függvényt arra használjuk, hogy megállapítsuk, egy adott változó értéke meg van megadva vagy sem. PÉLDA: 'age' IS NULL -> ez azt jelenti, hogy azok a találatok érdekesek nekünk, akik nem adták meg az életkorukat. Ezt lehet tagadni is, PÉLDA: 'age' IS NOT NULL -> ez pedig azokat a felhasználókat fogja listázni, ahol az életkor adatot megadták.",
                        txtOr: "Az OR logikai változó célja, hogy akkor adjon vissza találatot, ha legalább 2 változó valamelyik értéke a feltételnek megfelel. PÉLDA: 'sex' = \"man\" OR 'age' < 30 -> ez azt jelenti, hogy azokra a találatokra vagyunk kíváncsiak, akik vagy férfiak, vagy 30 éven aluliak. Komplexebb PÉLDA: ('sex' = \"man\" AND 'age' < 25) OR ('sex' = \"man\" AND 'age' BETWEEN(25,50)) -> ez azt jelenti, hogy azok a találatok kellenek nekünk, akik férfiak ÉS 25 év alattiak, VAGY férfiak és 25 és 50 közt vannak.",
                        txtAnd: "Az AND logikai változó célja, hogy akkor adjon vissza találatot, amikor legalább 2 változó mindkét értéke a feltételeknek megfelelő. PÉLDA: 'sex' = \"woman\" AND 'age' BETWEEN(18,30) -> ez azt jelenti, hogy azok a felhasználók kellenek nekünk, akik nők és 18-30 év közöttiek.",
                        txtAny: "Az ANY függvényt akkor használjuk, hogyha egy adott változó értékének több megfeleltetési lehetősége is van. PÉLDA: eyecolor = ANY(\"brown\",\"blue\",\"green\") -> ez azt jelenti, hogy azok a felhasználók kellenek nekünk, akiknek a szeme színe barna, zöld vagy kék. Másik PÉLDA: eyecolor = NOT ANY(\"blue\", \"green\") -> ez azt jelenti, hogy azok a felhasználók kellenek nekünk, akiknek a szeme színe nem kék vagy zöld.",
                        txtMax: "A MAX függvényt akkor használjuk, hogyha olyan felhasználók kellenek nekünk, akik egy bizonyos értéket a legnagyobbra állítottak. PÉLDA: MAX(age) -> ez azt jelenti, hogy azok a felhasználók kellenek nekünk, akinek életkora a legmagasabb.  A függvény a legnagyobb értéket veszi alapul, így például ha sok 70 éves van az adatbázisban, akkor több, ha pedig csak egy 70 éves van, akkor csak egy fog listázódni.",
                        txtMin: "A MIN függvényt akkor használjuk, hogyha olyan felhasználók kellenek nekünk, akik egy bizonyos értéket a legkisebbre állítottak. PÉLDA: MIN(age) -> ez azt jelenti, hogy azok a felhasználók kellenek nekünk, akinek életkora a legkisebb. A függvény a legkisebb értéket veszi alapul, így például ha sok 20 éves van az adatbázisban, akkor több, ha pedig csak egy 20 éves van, akkor csak egy fog listázódni.",
                        txtNot: "A NOT logikai változót általában függvények előtt használhatjuk (az IS függvényt kivéve), ha valamit tagadni szeretnénk vele. PÉLDA: email NOT CONTAIN(\"%gmail%\") -> ez azt jelenti, hogfy azokra a felhasználókra vagyunk kíváncsiak, akik nem gmail-os email címmel regisztráltak. Komplexebb PÉLDA: age NOT BETWEEN(18,30) AND sex NOT CONTAIN(\"wo%\") -> ez azt jelenti, hogy azok a felhasználók kellenek nekünk, akik NEM 18-30 évesek ÉS NEM nők.",
                        txtNull: "A NULL paramétert az IS függvénnyel együtt használjuk, így lásd ennek a leírását.",
                        txtLimit: "A LIMIT függvényt akkor használjuk, hogyha a lekérdezett rekordok számát szeretnénk behatárolni. PÉLDA: LIMIT 9, 100 -> ez azt jelenti, h a lekérdezett rekordok 10. elemétől kezdve 100 elemet szeretnénk megjeleníteni csak.",
                        txtBetween: "A BETWEEN függvényt komplexebb lekérdezésekhez használjuk, ami használható más függvényekkel együtt. Célja az, hogy tömörebben megfogalmazzuk, milyen határértékek közt szeretnénk megjeleníteni találatokat. PÉLDA: age = BETWEEN(MIN(age) + 5, MAX(age) - 4) AND sex = \"WOMAN\" -> ez azt jelenti, hogy azokra a találatokra vagyunk kíváncsiak, akiknek az életkoruk 5-tel több, mint a minimum, illetve az életkoruk 4-gyel kevesebb, mint a maximum és nők. Ezt még egyszerűbben leírva, PÉLDA: age = BETWEEN(MIN(age) + 5, MAX(age) - 4) AND sex CONTAIN(\"WO%\")",
                        txtContain: "A CONTAIN függvényt akkor használjuk, hogyha azokra találatokra vagyunk kíváncsiak, amik egy adott string mintának megfeleltethetőek. PÉLDA: email CONTAIN(\"%tk.%\") -> ez azt jelenti, hogy azokra a felhasználókra vagyunk kíváncsiak, akiknek email címében szerepel a \"tk.\" kifejezés.",
                    },
                    statuses: {
                        noCode: "Várom a kód megadását.",
                        noSpace: "A kódodban nem használsz szóközöket. Kérlek pótold!",
                        noResult: "A megadott feltételre nincs találat, így kérlek módosítsd a kódot.",
                        allRight: "A beállított szűrőfeltétel jónak tűnik.",
                        loadedCode: "A múlt alkalommal használt, és most betöltött kód.",
                        errors: {
                            errorNotVar: "A kódban olyan változót használsz, ami a \"Változók\" panelen nem szerepel.",
                            errorNotOperand: "A kódban nem találtam matematikai szimbólumot.",
                            errorNotEqualVar: "A kódban van olyan változóra való hivatkozás, amely nem egyezik a projektben használható változókkal.",
                            errorMissingParts: "A kódban van olyan változó, ahol nincs se matematikai szimbólum, se érték megadva.",
                            errorLogicOperands: "A kódban használsz logikai műveletet, de nincs mögötte semmilyen kódrészlet.",
                            errorUseLimitFunc1: "A LIMIT függvény paramétereit vesszővel elválasztva kell meghatározni.",
                            errorUseLimitFunc2: "A LIMIT függvény 2 paramétere csak egész szám lehet.",
                            errorWrongBrackets: "A kódban zárójelek használata problémás, emiatt kérlek nézd át újra.",
                            errorFirstLimitFunc: "A LIMIT függvény nem lehet az első utasítás a kódban, csak az utolsó.",
                            errorWrongQuoteOnVar: "A kódban van olyan változó, ahol az idézőjelezést nem megfelelően használtad.",
                            errorNotLastLimitFunc: "A LIMIT függvényt a kódban csak az utolsó helyen használhatod.",
                            errorWrongQuoteOnValue: "A kódban van olyan érték, ahol az idézőjelezést nem megfelelően használtad.",
                            errorWrongISFunctionUse: "A kódban rosszul használod az IS funkciót. Kérlek ellenőrizd!",
                            errorUseBetweenFuncParam: "A BETWEEN függvény mindkét paraméterében vagy számokat, vagy a MIN és MAX függvényeket használhatod csak.",
                            errorWrongFunctionOpenBracket: "A függvény után nem találom a \"(\" jelet, ami probléma.",
                        }
                    }
                },
                form: {
                    lblSeed: "A seed értéke",
                    lblIDSeq: "ID-k egymás után",
                    lblFirstLine1: "A kiválasztott válaszadók",
                    lblFirstLine2: "-a",
                    lblSecondLine1: "Pontosan",
                    lblSecondLine2: "számú válaszadó",
                    lblThirdLine1: "-tól",
                    lblThirdLine2: "-ig",
                },
                modal: {
                    colFirst: "A kérdés",
                    colSecond: "A tulajdonság",
                },
                btnAddNewVar: "Új változó hozzáadása",
                btnSaveSample: "A minta mentése",
                btnAddAllUsers: "Az összes felhasználó betöltése",
                btnResetFilters: "A szűrések resetelése",
                btnRefreshSample: "A minta frissítése",
            },
            modules: {
                surveyName: "Kérdőívek",
                surveyText: "Hozzáadok egy kérdőívet a projekthez",
                locName: "Helyszín",
                locText: "Hozzáadom a helyszín modult",
                actName: "Aktivitás",
                actText: "Hozzáadom az aktivitás modult"
            },
            usersTable: {
                titleName: "Név",
                titleEmail: "Email",
                titleRemove: "Törlöm"
            },
			logTable: {
				type: "A log típusa",
				desc: "A log leírása",
				cycles: "Mérési ciklus időpontja",
				created: "A log létrehozásának ideje"
			},
            surveyTable: {
                titleName: "Név",
                titleSurvey: "A kérdőív kiválasztása"
            },
            triggerTable: {
                titleName: "A sablon neve",
                titleTrigger: "A trigger kiválasztása",
                titleModuleName: "Az érintett modul",
                titleOperationName: "Trigger művelet",
            },
            validating: {
                name: "Meg kell adni a projekt nevét, melynek legalább 5 karakterből kell állnia",
                acronym: "Meg kell adni a projekt rövidítését, melynek legalább 2 karakterből kell állnia",
                endDate: "Meg kell adni a projekt befejezésének dátumát",
                endTime: "Meg kell adni a projekt befejezésének idejét",
                startDate: "Meg kell adni a projekt kezdetének dátumát",
                startTime: "Meg kell adni a projekt kezdetének idejét",
                desc: "Meg kell adni a projekt leírását, melynek legalább 10 karakterből kell állnia",
				nameResponder: "A válaszadó neve min. 5, max. 50 karakterből állhat",
            },
            messages: {
                newSuccess: "Az új projekt létrehozása sikeresen megtörtént.",
                updateSuccess: "A projekt adatait sikeresen módosítottam."
            },
            triggers: {
                tabs: {
                    time: "Idő beállítása",
                    location: "Lokáció beállítása",
                    notification: "Értesítés beállítása",
                },
				times: {
					set: "beállítás",
					withTimeSlot: "idősávval",
					withCustomTimeSlot: "testreszabott idősávval",
					default: "Alapbeállítások",
					timeSlot: "Idősávok meghatározása",
					startTimeSlot: "Idősáv kezdete",
					endTimeSlot: "Idősáv vége",
					color: "Színe",
					dateStart: "Dátum kezdete",
					timeStart: "Időpont kezdete",
					dateEnd: "Dátum vége",
					timeEnd: "Időpont vége",
					loadTemp: "Sablon betöltése",
					saveTemp: "Sablon mentése",
					removeRole: "A beállított dátumok alapján a meglévő szabály törlése",
					savedTriggers: "Már rögzített triggerek",
					setNewTrigger: "Új triggert szeretnék beállítani",
					noTriggerSetting: "Még nincs rögzítve trigger szabály",
				},
                nots: {
					addPlace: "Adj meg egy helyet...",
					searchMap: "Keress egy helyet, és jelöld meg a térképen",
					zoomDefault: "Nagyítás az alapértelmezett méretre",
                    blockTitle1: "Trigger választása",
                    blockTitle2: "Az értesítés beállítása",
                    blockTitle3: "Már rögzített értesítések",
                    selectATrigger: "Válassz egy triggert",
                    beforeNotification: "Értesítés a trigger kezdete előtt",
                    inActionNotification: "Értesítés a trigger működése alatt",
                    afterNotification: "Értesítés a trigger vége után",
                    beforeTime: "Mennyivel előtte?",
                    inActionTime: "Hányszor ismétlődjön?",
                    afterTime: "Mennyivel utána?",
                    pushNoti: "Mobil alapú Push értesítés",
                    emailNoti: "Email értesítés",
                    noNotiText: "Még nincs rögzítve értesítés",
                    addNewTriggerBtn: "Új triggert szeretnék beállítani",
                    withMinute: "perccel",
                    withTimes: "alkalommal",
                    setTimeTrigger: "időtrigger beállítás",
                    setLocationTrigger: "lokáció trigger beállítás",
                    setupNotification: "értesítés beállítva",
					form: {
						titleUpTime: "Triggerfeltétel minimális működési ideje",
						titleAddPlace: "Helyszín hozzáadása",
						titleShortDesc: "Rövid leírás",
						titleDefaultSet: "Trigger alapbeállítások",
						titleTriggerType: "Add meg a trigger típusát",
						titleBetweenTime: "Két trigger közt eltelt minimális idő",
						titleDistanceFrom: "Távolság ettől a ponttól",
						titleLocationMove: "A helyszín mozgatható",
						titleSavedLocations: "Már rögzített helyszínek",
						btnNewLocation: "Új triggert szeretnék beállítani",
						textLocation: "helyszín trigger",
						textNoLocation: "Még nincs rögzítve helyszín",
						phOnlyNumber: "A mezőbe csak számokat írhatsz",
					}
                }
            },
			diags: {
				title: "Projekt diagnosztikák",
				desc: "A kiválasztott projekthez tartozó diagnosztikák megjelenítése. A legfrissebb adatok vannak a táblázat elején!",
				table: {
					name: "Válaszadó neve",
					diagType: "Diagnosztika típusa",
					diagDesc: "Diagnosztika leírása",
					created: "Létrehozás dátuma",
				}
			},
			disabled: "Ehhez a projekthez nincs semmilyen jogosultságod!",
            btnImport: "Válaszadók importálása",
			btnAddNewUser: "Új válaszadó hozzáadása",
            btnSamplingOpen: "Mintavételezés megnyitása",
            btnSamplingClose: "Mintavételezés bezárása",
			btnCreateNewExport: "Új CSV export fájl készítése",
            createNewProjectText: "Új projekt létrehozása",
            processNewProjectText: "Létrehozás folyamatban...",
        },
        survey: {
			tabs: {
				viewer: "Nézet",
				designer: "Tervező",
				settings: "Beállítások",
				flowchart: "Folyamatábra",
				variables: "Változók"
			},
			surveyTypes: {
				traditional: "Hagyományos kérdőív típus",
				traditionalDesc: "Itt lehet általános kérdőíveket szerkeszteni, ahol az összes funkcionalitást fel lehet használni.",
				register: "Regisztrációs kérdőív típus",
				registerDesc: "Itt lehet regisztrációs kérdőíveket szerkeszteni, amelynek segítségével felhasználókat tudunk projektekhez kapcsolni.",
				panel: "Panel kérdőív típus",
				panelDesc: "Itt lehet panel kérdőíveket szerkeszteni, hogy új válaszadókat tudjunk hozzáadni az adatbázisunkhoz."
			},
            titles: {
                list: "Kérdőív Tervező",
                create: "Új kérdőív létrehozása",
                defaults: "Az új kérdőív alapértelmezett adatai",
                untitle: "Írd ide a kérdésed",
                undesc: "Add meg a kérdésed leírását",
                unkey: "Add meg a változó nevét, vagy a rendszer generál egyet",
                selectType: "Válassz egy kérdéstípust, és adj hozzá egy új kérdést",
				defaultSurveySettings: "A kérdőív általános beállításai",
            },
            texts: {
                list: "Az alábbiakban a jelenleg futó kérdőívek találhatók.",
                create: "Az alábbi szerkesztő segítségével tudod összeállítani a kérdőívet. A sorrendiség cserét a drag&drop technikával tudod kivitelezni.",
                selector: "Az alábbiakban válaszd ki, hogy milyen kérdőív típust szeretnél szerkeszteni",
				autoTranslateText: "A rendszer képes arra, hogyha többnyelvű egy adott kérdőív, akkor automatikusan lefordítsa a többi megadott nyelvre is a kérdéseket és az arra adható válaszokat. Alapértelmezetten ez a funkció be van kapcsolva. Ha nem akarod használni, akkor kapcsold ki ezt a funkciót.",
				autoTranslateTitle: "Bekapcsolom az automatikus fordítást",
				autoTranslateSuccess: "A szöveg automatikus fordítása sikeresen megtörtént.",
            },
			labels: {
				answerName: "Add meg a válaszlehetőséget",
				answerLabel: "A válasz címkéje",
			},
            hints: {
				notRemove: "Nem törölhető, mert legalább egy projekthez tartozik.",
                addNewQuestion: "Új kérdés hozzáadása",
				successSettings: "Sikeresen beállítottad a kérdőívet. Most már a tervező fül is aktív.",
				warningDefaultCode: "Figyelem! A kérdés változóját módosítsd kérlek, mert alapértelmezett állapotban van. Engedélyezett karakterek: betű, szám és _ karakter.",
				warningDefaultSurveyName: "Figyelem! A kérdőív nevét meg kell adni.",
				warningDefaultSurveyCode: "Figyelem! A kérdőv rövid nevét módosítani kell, mert alapértelmezett.",
				warningDefaultAddedCode: "Figyelem! A kérdés nevét és változóját módosítsd kérlek, mert egy már meglévő kérdés alapján lett másolva.",
				warningSurveySpecialCodes: "Figyelem! A kérdőív rövidítése csak betűket és számokat tartalmazhat!",
				warningWrongCode: "Kérlek ne használ a változóban 2-nél több _ karaktert!",
				errorCodeExists: "A megadott kód már létezik, ezért kérlek válassz másikat!",
				errorQuestionType: "Az URL-ben megadott paraméter nem egyezik meg a kérdőív típusával!"
            },
            modals: {
                title: "A kiválasztott kérdőív törlésének megerősítése",
                text: "Biztosan törölni szeretnéd ezt a kérdőívet?",
                warningTitle: "Figyelem! A művelet nem hatható végre!",
                warningText: "A kiválasztott kérdőív jelenleg egy aktív projekthez tartozik, ami így nem törölhető. Ha törölni szeretnéd a kérdőívet, akkor előtte töröld azt a projektből.",
				changeTypeText: "Amennyiben végre szeretnéd hajtani ezt a folyamatot, az eddigi beállításaid, a kérdésre adható válaszaid (ha vannak) törlődni fognak. Ez a folyamat nem visszaállítható!",
				changeTypeTitle: "Figyelem! A kérdés típusának változtatására készülsz",
                removeAnswerTitle: "A kiválasztott válasz törlésének megerősítése",
                removeQuestionTitle: "A kiválasztott kérdés törlésének megerősítése",
                removeQuestionText: "Biztosan törölni szeretnéd ezt a kérdést? Ha igen, akkor az összes hozzá tartozó válasz is törlődni fog.",
                removeAnswerText: "Biztosan törölni szeretnéd ezt a választ?",
            },
			options: {
				title: "Beállítások",
				required: "Kötelező",
				multiple: "Több válaszlehetőség",
				randomize: "Véletlenszerű",
				other: "Egyéb, éspedig",
				dontknow: "Nem tudom",
				notanswer: "Nem válaszol",
			},
			questionTypes: {
				radio: "Radio gombok",
				checkbox: "Checkboxok",
				longText: "Hosszú szöveg",
				shortText: "Rövid szöveg",
				number: "Szám",
				email: "Email cím",
				phoneNumber: "Telefonszám",
				date: "Dátum",
				time: "Idő",
				dateTime: "Dátum és idő"
			},
			settingMenus: {
				default: "Általános beállítások",
				view: "Megjelenés",
				import: "Importálás",
				export: "Exportálás",
				chooseMenu: "Kérlek válassz egy menüpontot",
			},
			fullTypes: {
				blockChoices: "Választások",
				blockRatings: "Rangsorolások és értékelések",
				blockBlocks: "Blokkok",
				blockTexts: "Szövegek",
				blockDates: "Dátumok és idők",
				blockUploads: "Feltöltések",
				blockContacts: "Kapcsolati adatok",
				radio: "Radio gombok",
				checkbox: "Checkboxok",
				longText: "Hosszú szöveg",
				shortText: "Rövid szöveg",
				number: "Szám",
				email: "Email cím",
				phoneNumber: "Telefonszám",
                website: "Weboldal",
				date: "Dátum",
				time: "Idő",
				dateTime: "Dátum és idő",
                pictureChoice: "Képválasztó",
                yesNo: "Igen / Nem",
                matrix: "Mátrix",
                opinionScale: "Véleményskála",
                likertScale: "Likert-skála",
                ranking: "Rangsorolás",
                rating: "Értékelés",
                textBlock: "Szöveg blokk",
                pictureBlock: "Kép blokk",
                videoBlock: "Videó blokk",
                soundBlock: "Hang blokk",
                fileUpload: "Fájlfeltöltés",
                videoUpload: "Videófeltöltés",
                photoUpload: "Képfeltöltés",
                soundUpload: "Hangfeltöltés",
			},
			types: {
				numInt: "Egész szám",
				numReal: "Valós szám",
				numLabel: "Válaszd ki a megadni kívánt szám típusát",
				labelName: "A label neve",
				textLabel: "Add meg a karakterek max. hosszát",
				phoneLabel: "Válaszd ki, hogy melyik országkód alapján validáljuk a telefonszámot",
				textWarning: "Max karakter: {count}",
				textNoSettings: "Az adott kérdéstípusnak nincsenek egyéb beállítási lehetőségei.",
			},
            settings: {
                main: {
                    phName: "A kérdőív neve (max. 100 karakter)",
                    phAcro: "A kérdőív rövid neve (max. 20 karakter)",
                    phDesc: "A kérdőív leírása (max. 255 karakter)",
                    surveyName: "Add meg a kérdőíved nevét",
                    surveyAcro: "Add meg a kérdőíved rövid nevét, aminek egyedinek kell lennie",
                    surveyDesc: "Add meg a kérdőíved leírását",
                    surveyLang: "Válaszd ki, hogy mely nyelveken szeretnéd a kérdőívet használni",
                },
                validating: {
                    surveyName: "Kérlek add meg a kérdőíved nevét min. 5, max. 100 karakterben",
                    surveyAcro: "Kérlek add meg a kérdőíved rövid nevét, aminek egyedinek kell lennie min. 3, max. 20 karakterben",
                    surveyDesc: "Kérlek add meg a kérdőíved leírását min. 10, max. 255 karakterben",
                    codeAlreadyExists: "Ez a kód már létezik az adatbázisban.",
					codeExceptsChars: "A megadott kódban vannak olyan karakterek, amik nem engedélyezettek.",
                },
            },
            dropdown: {
                name: "Funkciók",
                add: "Új kérdés hozzáadása",
                set: "Beállítások",
                del: "Törlés"
            },
            answers: {
                text: 'Rövid szöveges válasz',
            },
			errors: {
				server: 'Szerver oldali hiba',
				wrongAddedCode: 'Abban az esetben nem tudsz kérdést hozzáadni, amikor már valamikor megtetted azt, de a kérdés neve és a kérdéskód nem lett átírva.',
				wrongCopiedCode: 'Abban az esetben nem tudsz másolni egy kérdést, amikor már valamikor megtetted azt, de a kérdés neve és a kérdéskód nem lett átírva.',
			},
            btnCopy: "A kérdőív másolása",
			allTypes: "Az összes kérdéstípus",
            defaultDesc: "Nincs leírás...",
            defaultTitle: "Nincs cím...",
			questionValue: "A kérdés változója",
			btnAddNewSection: "Új szekció hozzáadása",
            createNewSurveyText: "Új kérdőív létrehozása",
        },
		textcontents: {
			titles: {
				editName: "Egy szöveges tartalom szerkesztése",
				editDesc: "Az űrlap segítségével rakható össze egy új szöveges tartalom, vagy egy már meglévő módosítható",
				listName: "Szöveges tartalmak",
				listDesc: "Az alábbiakban láthatóak a rendszerben jelenleg tárolt szöveges tartalmak",
			},
			tableList: {
				title: "A tartalom címe",
				removeText: "Tartalom törlésének státusza",
				removable: "Törölhető",
				notRemovable: "Nem törölhető",
			},
			form: {
				lbName: "A tartalom címe",
				phName: "Add meg a tartalom címét",
				lbDelete: "A tartalom törölhető",
			},
			validating: {
				title: "A tartalom neve legalább 4, maximum 100 karakterből álljon",
				desc: "A szöveges tartalom legalább 10 karakterből kell, hogy álljon",
				noEnglishContent: "Ne felejtsd el kitölteni az angol fordítást!",
			},
			modal: {
				deleteText: "Biztosan törölni szeretnéd a kívánt szöveges tartalmat? A művelet vonható vissza!",
				deleteTitle: "A szöveges tartalom törlésének megerősítése",
			},
			btnAdd: "Új szöveges tartalom hozzáadása",
			saveSuccess: "A szöveges tartalom mentése sikeresen megtörtént",
			removeSuccess: "A szöveges tartalom törlése sikeresen megtörtént",
		},
        articleeditor: {
            titleName: "Cikk címe",
            statusName: "Cikk státusza",
            titles: {
                list: "Cikkszerkesztő",
                edit: "Egy cikk szerkesztése",
            },
            descs: {
                list: "Az alábbiakban a frontend rendszerhez hozzáadott cikk tartalom látható.",
                edit: "Az alábbi űrlap segítségével módosíthatod a cikk tartalmát.",
                upload: "Ha szeretnéd, fel tudsz tölteni egy új képet a cikk számára.",
                phUpload: "Adj meg egy png vagy svg fájlt a cikk fotójának módosításához.",
            },
            messages: {
                errorEditor: "A kérdést és a választ angol és magyar nyelven is meg kell adni.",
                uploadSuccess: "A kép feltöltése sikeresen megtörtént",
                updateSuccess: "A cikk módosítása sikeresen megtörtént.",
                updateStatusSuccess: "A cikk státuszának módosítása sikeresen megtörtént.",
            },
        },
        faqeditor: {
            titles: {
                add: "GYIK hozzáadása",
                list: "GYIK szerkesztő",
                edit: "Egy GYIK szerkesztése",
            },
            descs: {
                add: "Az alábbi űrlap segítségével új GYIK-et adhatsz hozzá a rendszerhez.",
                list: "Az alábbiakban a rendszerhez eddig hozzáadott GYIK tartalom látható.",
                edit: "Az alábbi űrlap segítségével módosíthatod a GYIK tartalmát."
            },
            form: {
                phId: "Add meg a GYIK rövid azonosítóját, mint pl: question-one",
                phTitle: "Add meg a kérdést (min. 5 karakter)",
                phDesc: "Add meg a választ (min. 10 karakter)",
                labelId: "GYIK azonosító",
                labelTitle: "A kérdés",
                labelDesc: "Válasz a kérdésre",
                labelActive: "A GYIK státuszának beállítása"
            },
            messages: {
                errorEditor: "A kérdést és a választ angol és magyar nyelven is meg kell adni.",
                saveSuccess: "A mentés sikeresen megtörtént.",
                deleteSuccess: "A törlés sikeresen megtörtént.",
                updateSuccess: "A módosítás sikeresen megtörtént.",
                updateStatusSuccess: "A státusz módosítása sikeresen megtörtént.",
            },
            modal: {
                title: "A kiválasztott GYIK törlésének megerősítése",
                text: "Biztosan törölni szeretnéd a kiválasztott GYIK-et?"
            },
            faqIdName: "GYIK Azonosító",
            faqTitleName: "A kérdés",
            faqActivated: "A GYIK státusza",
        },
        octres: {
            titleName: "A kutató neve",
            titles: {
                list: "Az Octopus kutatói",
                edit: "Egy kutató szerkesztése",
                fileUp: "Ha szeretnéd, akkor tölts fel egy új képet.",
                fileAct: "Aktuális kép."
            },
            descs: {
                list: "Az alábbiakban az Octopus kutatói láthatóak.",
                edit: "A kutató adatainak szerkesztéséhez használd az alábbi űrlapot."
            },
            form: {
                phpPos: "Add meg a kutató pozícióját (min 5 karakter)",
                phTitle: "Add meg a kutató nevét (min 5 karakter)",
                phUpload: "Adj meg egy png vagy svg fájlt a kutató fényképének módosításához",
                phDesc: "Add meg a kutató leírását (min 10 karakter)",
                labelPos: "A kutató pozíciója:",
                labelTitle: "A kutató neve:",
                labelDesc: "A kutató leírása:"
            },
            messages: {
                errorEditor: "Az adatokat angolul és magyarul is meg kell adni.",
                uploadSuccess: "A kép feltöltése sikeresen megtörtént",
                updateSuccess: "A kutató adatai sikeresen módosultak.",
            },
        },
        nlsubs: {
            title: "Hírlevélre feliratkozottak",
            text: "Az alábbiakban a hírlevélre feliratkozókat mutatjuk be, akik ezt a frontend oldalunkon tették meg.",
            table: {
                email: "Email cím",
                createdat: "Regisztráció dátuma"
            }
        },
        ressubs: {
            title: "Kutatásra feliratkozottak",
            text: "Az alábbiakban a kutatásra feliratkozókat mutatjuk be, akik ezt a frontend oldalunkon tették meg.",
            table: {
                fname: "Keresztnév",
                lname: "Vezetéknév",
                email: "Email cím",
                phone: "Telefonszám",
                createdat: "Regisztráció dátuma"
            }
        }
    }
}
